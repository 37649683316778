import React, {useState, useEffect} from 'react';
import PatientDoctorDocumentsTable from '../DoctorComponent/PatientDoctorDocumentsTable';

const PatientAssignedDocuments = () => { 
    const [patientId, setPatientId] = useState(localStorage.getItem('patientId'));

    return (
        <div className='m-3'>
            <PatientDoctorDocumentsTable patientId={patientId} isDownload={true} />
        </div>
    )
};

export default PatientAssignedDocuments;