import axios from 'axios';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import PatientDoctorDocumentsTable from './PatientDoctorDocumentsTable';

const DocumentsInAppointments = ({ patientId, doctorId }) => {
    const [appointments, setAppointments] = useState(null);
    const [selectedAppointments, setSelectedAppointments] = useState([]);
    const [documentsByAppointment, setDocumentsByAppointment] = useState({});
    const [token] = useState(localStorage.getItem('token'));

    //Si me llega el patientId, entonces busco las citas del paciente, si no, busco las citas del doctor
    const petitionAxios = patientId ? `${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAppointments/${patientId}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAppointmentsDoctor/${doctorId}` ;

    useEffect(() => {
        axios.get(petitionAxios, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res.data);
            setAppointments(res.data);
        }).catch((error) => {
            console.error('Error al obtener los documentos del paciente:', error);
        })
    }, [patientId, doctorId])

    const onHandleClickVer = (appointmentId) => {
        setSelectedAppointments((prevSelected) => {
            if (prevSelected.includes(appointmentId)) {
                return prevSelected.filter(id => id !== appointmentId);
            } else {
                return [...prevSelected, appointmentId];
            }
        });

        if (!documentsByAppointment[appointmentId]) {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/getDocumentsAppointment/${appointmentId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setDocumentsByAppointment((prevDocuments) => ({
                    ...prevDocuments,
                    [appointmentId]: res.data || {}
                }));
            }).catch((error) => {
                console.error('Error al obtener los documentos del paciente:', error);
            });
        }
    };

    return (
        <div className="flex-grow py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-h-[calc(2*220px)] overflow-y-auto bg-white rounded-lg shadow">
                <ul className="divide-y divide-gray-200">
                    {appointments && appointments.map((appointment) => (
                        <li key={appointment._id} className="px-4 py-4 sm:px-6">
                            <div className="flex items-center justify-between">
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate">
                                        {appointment.doctor?.fullName || (appointment.patient?.firstName +' '+ appointment.patient?.firstLastName)}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        {appointment.notes || 'Sin notas'}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {moment(appointment.date).tz('America/Bogota').format('DD/MM/YYYY')}
                                    </p>
                                </div>
                                <div className="ml-4">
                                    <button
                                        onClick={() => onHandleClickVer(appointment._id)}
                                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out"
                                        aria-expanded={selectedAppointments.includes(appointment._id)}
                                        aria-controls={`appointment-details-${appointment._id}`}
                                    >
                                        {selectedAppointments.includes(appointment._id) ? 'Ocultar' : 'Ver Documentos'}
                                    </button>
                                </div>
                            </div>
                            <div className="m-0 p-0 overflow-y-auto">
                                {selectedAppointments.includes(appointment._id) && (
                                    <PatientDoctorDocumentsTable
                                        appointmentId={appointment._id}
                                        documentsInAppointment={documentsByAppointment[appointment._id]}
                                        showAppointments={false}
                                    />
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
};


export default DocumentsInAppointments;

// {showTable && (
//                     <PatientDoctorDocumentsTable
//                         documentsInAppointment={documents && documents}
//                         showAppointments={false}
//                     />
//                 )}