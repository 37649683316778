import React, { useState, useEffect } from "react";
import AgoraUIKit from "agora-react-uikit";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import HistoriaClinica from "../components/Formularios/HistoriaClinica";
import PersonalInfo from "../components/VideoCall/PersonalInfoModal"; // Importar el componente PersonalInfo
import PreescribirForm from "../components/VideoCall/PreescribirForm"; // Importar el componente PersonalInfo
import NotasDeConsulta from "./VideoCall/NotasConsulta";
import Swal from "sweetalert2";
import PatientDoctorDocumentsTable from "./DoctorComponent/PatientDoctorDocumentsTable";
import ConsentimientoInformado from "./VideoCall/ConsentimientoInformado";
import DocumentsVideoCall from "./VideoCall/DocumentsVideoCall";
import Chat from "./VideoCall/Chat";

const VideoCall = () => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const [videocall, setVideocall] = useState(false);
  const [rtcProps, setRtcProps] = useState(null);
  const [showResume, setShowResume] = useState(false);
  const [selectedForm, setSelectedForm] = useState("");
  const [role, setRole] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [formSize, setFormSize] = useState("normal");
  const [videoSize, setVideoSize] = useState("normal");
  const [patientInfo, setPatientInfo] = useState(null);
  const [showConsentimiento, setShowConsentimiento] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);

    if (userRole === "patient") {
      setShowConsentimiento(true);
    } else {
      setShowConsentimiento(false);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const consentimientoParam = urlParams.get("showConsentimiento");

    if (consentimientoParam === "false") {
      setShowConsentimiento(false);
      setVideocall(true); // Iniciar la videollamada automáticamente
    }
    const fetchToken = async () => {
      try {
        const token1 = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/clinic/agora-token/${appointmentId}`,
          {
            headers: {
              Authorization: `Bearer ${token1}`,
            },
          }
        );
        const token = response.data.token;

        setRtcProps({
          appId: process.env.REACT_APP_AGORA_APP_ID,
          channel: appointmentId,
          token: token,
        });
      } catch (error) {
        console.error("Error al obtener el token de Agora:", error);
      }
    };

    const fetchPatientInfo = async () => {
      try {
        const token1 = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
          {
            headers: {
              Authorization: `Bearer ${token1}`,
            },
          }
        );
        setPatientInfo(response.data);
      } catch (error) {
        console.error("Error al obtener la información del paciente:", error);
      }
    };

    fetchToken();
    fetchPatientInfo();
  }, [appointmentId]);

  const handleEndCall = () => {
    setVideocall(false);
  };

  const handleConsentAccepted = () => {
    setShowConsentimiento(false); // Ocultar consentimiento
    setVideocall(true); // Iniciar la videollamada
  };

  const handleFinishCall = async () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Recuerda revisar que todos los documentos fueron diligenciados y enviados correctamente.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Finalizar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        finalizeAppointment();
      }
    });
  };

  const finalizeAppointment = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/finalizeAppointment/${appointmentId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Llamada finalizada con éxito!",
        text: "La llamada ha sido finalizada correctamente.",
      }).then(() => {
        setShowResume(true);
      });
    } catch (error) {
      console.error("Error al finalizar la llamada:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ocurrió un error al finalizar la llamada. Por favor, inténtelo nuevamente.",
      });
    }
  };

  const handleBack = () => {
    window.history.back(1);
    localStorage.removeItem("notasConsulta");
    localStorage.removeItem("formData");
  };

  const handleStartCall = () => {
    setVideocall(true);
    setSelectedForm("personalInfo"); // Selecciona "Información Personal" al iniciar la llamada
  };
  return (
    <div className="flex flex-col min-h-screen">
      {/* Barra superior */}
      <header className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-black p-4 flex flex-col md:flex-row justify-between items-center shadow-md">
        {role === "doctor" && !showResume && (
          <button
            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 rounded-md px-2 py-1"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        )}
        {role === "doctor" && !showResume && (
          <div className="flex-grow flex items-center justify-end ml-[800px] space-x-6">
            <button
              className="bg-red-500 text-white rounded-md px-2 py-1 "
              onClick={handleFinishCall}
            >
              Finalizar llamada
            </button>
          </div>
        )}
        {!showResume && (
          <div className="flex-grow flex items-center justify-end space-x-6">
            <h1 className="text-xl font-semibold text-white">Video Consulta</h1>
            <button
              className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900  text-white rounded-md px-2 py-1 flex"
              onClick={handleBack}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M15 19l-7-7 7-7"></path>
              </svg>
              Volver
            </button>
          </div>
        )}
        {showResume && (
          <div className="flex-grow flex items-center justify-end space-x-6">
            <h1 className="text-xl font-semibold text-white">Video Consulta</h1>
            <button
              className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900  text-white rounded-md px-2 py-1 flex"
              onClick={() => navigate("/doctor")}
            >
              Confirmar
            </button>
          </div>
        )}
      </header>

      {showResume && <DocumentsVideoCall appointmentId={appointmentId} />}

      {showConsentimiento && role === "patient" ? (
        <ConsentimientoInformado
          appointmentId={appointmentId}
          onConsentAccepted={handleConsentAccepted} // Pasar la función aquí
        />
      ) : (
        !showResume && (
          <>
            {/* Menú lateral */}
            {role === "doctor" && (
              <div
                className={`bg-white p-4 shadow-lg fixed top-16 left-0 h-screen z-10 transition-transform transform ${
                  menuOpen ? "translate-x-0" : "-translate-x-full"
                }`}
                style={{ width: "16rem" }}
              >
                <ul className="space-y-4">
                  <li>
                    <button
                      className="text-left w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-md px-4 py-2"
                      onClick={() => {
                        setSelectedForm("personalInfo");
                        setMenuOpen(false); // Cerrar el menú al seleccionar esta opción
                      }}
                    >
                      Información Personal
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-left w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-md px-4 py-2"
                      onClick={() => {
                        // Puedes agregar lógica aquí si necesitas hacer algo al seleccionar "Expediente"
                        setMenuOpen(false); // Cerrar el menú
                      }}
                    >
                      Expediente
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-left w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-md px-4 py-2"
                      onClick={() => {
                        setSelectedForm("form1");
                        setMenuOpen(false); // Cerrar el menú al seleccionar esta opción
                      }}
                    >
                      Historia Clínica
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-left w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-md px-4 py-2"
                      onClick={() => {
                        setSelectedForm("documentos");
                        setMenuOpen(false); // Cerrar el menú al seleccionar esta opción
                      }}
                    >
                      Documentos
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-left w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-md px-4 py-2"
                      onClick={() => {
                        setSelectedForm("chat");
                        setMenuOpen(false); // Cerrar el menú al seleccionar esta opción
                      }}
                    >
                      Chat
                    </button>
                  </li>

                  <li>
                    <button
                      className="text-left w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-md px-4 py-2"
                      onClick={() => {
                        setSelectedForm("notasDeConsulta");
                        setMenuOpen(false); // Cerrar el menú al seleccionar esta opción
                      }}
                    >
                      Notas de Consulta
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-left w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-md px-4 py-2"
                      onClick={() => {
                        setSelectedForm("preescribirForm");
                        setMenuOpen(false); // Cerrar el menú al seleccionar esta opción
                      }}
                    >
                      Preescribir
                    </button>
                  </li>
                </ul>
              </div>
            )}

            <main
              className={`flex-grow flex transition-transform transform ${
                menuOpen ? "ml-64" : "ml-0"
              }`}
            >
              {/* Lado izquierdo: Información Personal o Historia Clínica directamente */}
              {role === "doctor" && selectedForm === "personalInfo" && (
                <div className="w-full p-4 bg-white shadow-lg">
                  <PersonalInfo appointmentId={appointmentId} />
                </div>
              )}
              {role === "doctor" && selectedForm === "preescribirForm" && (
                <div className="w-full p-4 bg-white shadow-lg">
                  <PreescribirForm appointmentId={appointmentId} />
                </div>
              )}
              {role === "doctor" && selectedForm === "notasDeConsulta" && (
                <div className="w-full p-4 bg-white shadow-lg">
                  <NotasDeConsulta appointmentId={appointmentId} />
                </div>
              )}
              {role === "doctor" && selectedForm === "documentos" && (
                <div className="w-full p-4 bg-white shadow-lg">
                  <PatientDoctorDocumentsTable
                    patientId={patientInfo && patientInfo.patientId}
                  />
                </div>
              )}
              {role === "doctor" && selectedForm === "chat" && (
                <div className="w-2/3 h-48  bg-white shadow-lg">
                <Chat />
              </div>
              
              )}

              {role === "doctor" && selectedForm === "form1" && (
                <div
                  className={`transition-all ${
                    formSize === "normal" ? "w-2/3" : "w-full"
                  } bg-white p-4 shadow-lg relative`}
                >
                  {/*<button
                    className="absolute top-2 right-2 bg-gray-600 text-white rounded-md px-2 py-1"
                    onClick={() =>
                      setFormSize(formSize === "normal" ? "small" : "normal")
                    }
                  >
                    {formSize === "normal"
                      ? "Reducir Formulario"
                      : "Expandir Formulario"}
                  </button>
*/}
                  <h2 className="text-lg font-semibold mb-4">
                    Historia Clínica
                  </h2>

                  <div className="w-full h-[500px] border border-gray-300 rounded-md overflow-auto p-4">
                    <HistoriaClinica patientInfo={patientInfo} appointmentId={appointmentId}  />
                  </div>
                </div>
              )}

              {/* Lado derecho: Videollamada */}

              <div
                className={`transition-all flex ${
                  role === "doctor"
                    ? formSize === "normal"
                      ? "flex-col w-1/3"
                      : "w-full"
                    : "flex-row w-full"
                }`}
              >
                {role === "patient" && (
                  <div className="w-3/4 h-48  bg-white shadow-lg">
                    <Chat appointmentId={appointmentId} />
                  </div>
                )}
                <div className="flex flex-col h-full p-2 bg-white shadow-lg relative w-4/3">
                  {videocall && rtcProps ? (
                    <AgoraUIKit
                      rtcProps={rtcProps}
                      callbacks={{
                        EndCall: handleEndCall,
                      }}
                      style={{
                        width:
                          formSize === "normal" && !selectedForm
                            ? "100%"
                            : "100%",
                        height:
                          formSize === "normal" && !selectedForm
                            ? "100%"
                            : "100%",
                      }}
                    />
                  ) : (
                    <div className="flex items-center justify-center h-full w-screen">
                      <button
                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded-md"
                        onClick={handleStartCall}
                      >
                        Iniciar VideoConsulta
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </main>
          </>
        )
      )}
    </div>
  );
};

export default VideoCall;
