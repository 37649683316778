import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment-timezone';
import TableDocuments from './TableDocuments';
import { set } from 'react-hook-form';

const DoctorComponent = () => {
    const [showForm, setShowForm] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [patients, setPatients] = useState([]);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [appointmentNotes, setAppointmentNotes] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [appointmentDate, setAppointmentDate] = useState('');
    const [appointments, setAppointments] = useState([]); // Estado para almacenar las citas del paciente
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [filesToEdit, setFilesToEdit] = useState([]); // Variable para manejar los archivos que se van a subir

    const fetchDoctors = async () => {
        try {
            const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
            const token = localStorage.getItem('token'); // Obtén el token si es necesario para autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getDoctors/${clinicId}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Si usas autenticación basada en tokens
                },
            });

            setDoctors(response.data);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        }
    };

    const fetchPatients = async () => {
        try {
            const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
            const token = localStorage.getItem('token'); // Obtén el token si es necesario para autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPatients/${clinicId}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Si usas autenticación basada en tokens
                },
            });

            setPatients(response.data);
        } catch (error) {
            console.error('Error fetching patients:', error);
        }
    };


    const fetchAppointments = async (doctorId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAppointmentsDoctor/${doctorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Datos de citas recibidos:', response.data);
            const appointmentsData = response.data.map(appointment => {

                const utcDate = moment.utc(appointment.date);
                return {
                    title: 'Cita de control',
                    start: utcDate.toISOString(),
                    allDay: false,
                    backgroundColor: '#000aff',
                    borderColor: '#000aff',
                    extendedProps: {
                        notes: appointment.notes,
                        startTime: utcDate.format('HH:mm'),
                        patientName: `${appointment.patient.firstName} ${appointment.patient.firstLastName}`
                    }
                };
            });
            console.log('Datos de citas procesados:', appointmentsData);

            setAppointments(appointmentsData);
        } catch (error) {
            console.error('Error fetching appointments:', error);
            Swal.fire('Error', 'No se pudieron cargar las citas del doctor.', 'error');
        }
    };


    useEffect(() => {
        fetchDoctors();
        fetchPatients();
    }, []);



    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleCancel = () => {
        setShowAppointmentModal(false)

        setSelectedDoctor(null);
    };

    const handleGoBack = () => {
        setShowForm(false);
        setShowCalendar(false); // Oculta el calendario al volver
        setShowChangePassword(false);
        setShowProfile(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };
    const filtereddoctors = doctors.filter(doctor => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            doctor.fullName?.toLowerCase().includes(searchTermLower) ||
            doctor.email?.toLowerCase().includes(searchTermLower) ||
            doctor.cellNumber?.toLowerCase().includes(searchTermLower) ||
            doctor.idNumber?.toLowerCase().includes(searchTermLower)
        );
    });

    const toggleMenu = (doctor) => {
        setSelectedDoctor(doctor);
        setMenuVisible((prevVisible) => !prevVisible);
    };

    const handleMenuOptionClick = (option) => {
        console.log(`Option selected: ${option}`);
        setMenuVisible(false);

        if (option === 'Eliminar Doctor') {
            handleDeletedoctor();
        }
        if (option === 'Calendario') {
            setShowCalendar(true);
            fetchAppointments(selectedDoctor._id); // Carga las citas del paciente seleccionado
        }
        if (option === 'Agendar Cita') {
            setShowAppointmentModal(true); // Muestra el modal de agendar cita
        }
        if (option === 'Cambiar Contraseña') {
            setShowChangePassword(true);
        }
        if (option === 'Perfil') {
            setShowProfile(true);
        }
    };

    const handleDeletedoctor = async () => {
        try {
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Una vez eliminado, no podrás recuperar esta información.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                const token = localStorage.getItem('token');

                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/clinic/deleteDoctor/${selectedDoctor._id}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    Swal.fire('Eliminado', 'El doctor ha sido eliminado exitosamente.', 'success');
                    setDoctors(doctors.filter(doctor => doctor._id !== selectedDoctor._id));
                } else {
                    Swal.fire('Error', 'No se pudo eliminar el doctor. Inténtalo de nuevo.', 'error');
                }
            }
        } catch (error) {
            console.error('Error deleting doctor:', error);
            Swal.fire('Error', 'Ocurrió un error al eliminar el doctor.', 'error');
        }
    };



    const handleScheduleAppointment = async () => {
        try {
            const token = localStorage.getItem('token');
            const date = new Date(appointmentDate);

            const timezoneOffset = date.getTimezoneOffset();
            date.setMinutes(date.getMinutes() - timezoneOffset);
            const formattedDate = date.toISOString();

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/scheduleAppointment`,
                {
                    patientId: selectedPatient,
                    date: formattedDate,
                    notes: appointmentNotes,
                    doctorId: selectedDoctor._id, // Enviando el ID del doctor
                    service: selectedDoctor.service, // Enviando el servicio seleccionado
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                Swal.fire('Cita Agendada', 'La cita ha sido agendada exitosamente.', 'success');
                setShowAppointmentModal(false);
                setAppointmentDate('');
                setAppointmentNotes('');
                setSelectedPatient(null);
            } else {
                Swal.fire('Error', 'No se pudo agendar la cita. Inténtalo de nuevo.', 'error');
            }
        } catch (error) {
            console.error('Error scheduling appointment:', error);
            Swal.fire('Error', 'Ocurrió un error al agendar la cita.', 'error');
        }
    };


    const handleEditDoctor = async () => {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            // console.log(filesToEdit);

            const dataDoctorToSend = new FormData();
            for (const key in selectedDoctor) {
                dataDoctorToSend.append(key, selectedDoctor[key]);
            }

            for (const key in filesToEdit) {
                dataDoctorToSend.append(key, filesToEdit[key]);
            }

            // dataDoctorToSend.forEach((value, key) => {
            //     console.log('Key:', key, 'Value:', value);
            // });

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/editDoctor/${selectedDoctor._id}`,
                dataDoctorToSend,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                Swal.fire('Doctor Editado', 'El Doctor ha sido editado exitosamente.', 'success');
                setShowProfile(false);
                fetchDoctors();

            } else {
                Swal.fire('Error editando Doctor', 'El Doctor no pudo ser editado', 'error');
            }
        } catch (error) {
            console.error('Error updating patient information:', error);
            Swal.fire('Error editando Doctor', 'El Doctor no pudo ser editado', 'error');

        }
    };

    const lstDocumentsExists = [
        { nameToSave: 'foto_perfil', name: 'Foto Profesional', url: selectedDoctor?.urlProfessionalPhoto || '', isEdit: true },
        { nameToSave: 'cedulaPDF', name: 'Documento Identidad', url: selectedDoctor?.urlIdDocument || '', isEdit: true },
        { nameToSave: 'diploma_acta_grado', name: 'Diploma Grado', url: selectedDoctor?.urlUndergraduateDiploma || '', isEdit: true },
        { nameToSave: 'diploma_especializacion', name: 'Diploma Especialización', url: selectedDoctor?.urlSpecializationDiploma || '', isEdit: true },
        { nameToSave: 'homologacion_estudios', name: 'Homologación Estudios', url: selectedDoctor?.urlStudyHomologation || '', isEdit: true },
        { nameToSave: 'tarjeta_profesional', name: 'Tarjeta Profesional', url: selectedDoctor?.urlProfessionalCard || '', isEdit: true },
        { nameToSave: 'firma_profesional', name: 'Firma Profesional', url: selectedDoctor?.urlProfessionalSignature || '', isEdit: true },
        { nameToSave: 'rethus', name: 'ReTHUS', url: selectedDoctor?.urlRethusDocument || '', isEdit: true },
        { nameToSave: 'poliza_responsabilidad_civil', name: 'Poliza Responsabilidad Civil', url: selectedDoctor?.urlCivilLiabilityPolicyDocument || '', isEdit: true },
    ];

    if (showProfile) {
        return (
            <div className="flex flex-col min-h-screen">
                <div className="flex p-4 items-center justify-between bg-white shadow-md border-b border-gray-200">
                    <h1 className="text-2xl font-semibold text-gray-800">Editar Perfil del Doctor</h1>
                    <button
                        onClick={handleGoBack}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                    >
                        Volver
                    </button>
                </div>
                <main className="max-w-full mt-6">
                    <div className="max-w-full">
                        <form>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* Nombre Completo */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Nombre Completo</label>
                                    <input
                                        type="text"
                                        value={selectedDoctor.fullName}
                                        onChange={(e) => setSelectedDoctor({ ...selectedDoctor, fullName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Tipo de ID */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Tipo de ID</label>
                                    <select
                                        value={selectedDoctor.idType}
                                        onChange={(e) => setSelectedDoctor({ ...selectedDoctor, idType: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    >

                                        <option value="cc">CC</option>
                                        <option value="ti">TI</option>
                                        <option value="ce">CE</option>
                                        <option value="ps">PS</option>
                                        <option value="de">DE</option>
                                    </select>
                                </div>

                                {/* Número de ID */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de ID</label>
                                    <input
                                        type="text"
                                        value={selectedDoctor.idNumber}
                                        onChange={(e) => setSelectedDoctor({ ...selectedDoctor, idNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Correo Electrónico */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Correo Electrónico</label>
                                    <input
                                        type="email"
                                        value={selectedDoctor.email}
                                        onChange={(e) => setSelectedDoctor({ ...selectedDoctor, email: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Número de Teléfono */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de Teléfono</label>
                                    <input
                                        type="tel"
                                        value={selectedDoctor.cellNumber}
                                        onChange={(e) => setSelectedDoctor({ ...selectedDoctor, cellNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Servicio */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Servicio</label>
                                    <select
                                        value={selectedDoctor.service}
                                        onChange={(e) => setSelectedDoctor({ ...selectedDoctor, service: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    >
                                        <option value={selectedDoctor.service}>{selectedDoctor.service}</option>
                                        <option value="medicina_general">Medicina General</option>
                                        <option value="medicina_familiar">Medicina Familiar</option>
                                    </select>
                                </div>

                            </div>

                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={handleEditDoctor}
                                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                                >
                                    Guardar Cambios
                                </button>
                            </div>
                        </form>
                        {/* Tabla Visualizacion Y Descargas De Los Documentos */}
                        <TableDocuments
                            lstDocuments={lstDocumentsExists ? lstDocumentsExists : null}
                            filesToEdit={filesToEdit}
                            setFilesToEdit={setFilesToEdit}
                        />
                    </div>
                </main>
            </div>
        );
    }




    if (showCalendar) {
        return (
            <div className="flex flex-col  min-h-screen">
                <div className="flex p-2 items-center justify-between">
                    <h1 className="text-lg font-bold text-black ">Calendario</h1>
                    <button
                        onClick={handleGoBack}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                    >
                        Volver
                    </button>
                </div>
                <main className="flex-grow flex items-center justify-center p-2 -mt-2">
                    <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-3">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={appointments}
                            locales={[esLocale]}
                            locale="es"
                            timeZone="local"
                            dayCellClassNames={(date) =>
                                date.date.getDate() === new Date().getDate() &&
                                    date.date.getMonth() === new Date().getMonth()
                                    ? 'current-day'
                                    : ''
                            }
                            eventContent={(eventInfo) => {
                                const startTime = moment.utc(eventInfo.event.start).format('HH:mm');
                                return (
                                    <div style={{ backgroundColor: eventInfo.event.backgroundColor }}>
                                        <div className="font-semibold text-white text-xs">{eventInfo.event.title}</div>
                                        <div className=" text-white text-xs ">{startTime}</div>
                                    </div>
                                );
                            }}
                            eventClick={(info) => handleEventClick(info)}
                        />
                    </div>
                </main>
                {selectedEvent && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                        <div className="bg-white rounded-lg text-center shadow-md p-4 max-w-md w-full">
                            <h2 className="text-lg font-bold">{selectedEvent.title}</h2>
                            <p className="text-sm text-gray-800 font-semibold">Fecha: {moment(selectedEvent.start).format('YYYY-MM-DD')}</p>
                            <p className="text-sm text-gray-800 font-semibold">Hora: {moment.utc(selectedEvent.start).format('HH:mm')}</p>
                            <p className="text-sm text-gray-800 font-semibold">
                                Paciente: {selectedEvent.extendedProps.patientName}
                            </p>
                            <p className="text-sm mt-2">{selectedEvent.extendedProps.notes}</p>
                            <button
                                onClick={handleCloseModal}
                                className="mt-4 px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                )}
                <style>
                    {`
                    .fc-event {
                        background-color: #000aff !important; /* Asegúrate de que el color de fondo se aplique */
                        border: 1px solid #000aff;
                    }
                    .fc-daygrid-day {
                        visibility: visible;
                    }
                    .current-day {
                        background-color: #e7e6e6 !important;
                        border: 2px solid #fbc02d;
                    }
                    `}
                </style>
            </div>
        );
    }
    const closeChangePasswordModal = () => {
        setShowChangePassword(false);
    };

    const handleChangePassword = async () => {
        try {

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/changePasswordpatient`, {
                username: selectedDoctor.email,
                newPassword: newPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    title: 'Contraseña cambiada con exito!.',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
                setNewPassword('');
                closeChangePasswordModal();
            } else {
                Swal.fire({
                    title: 'Contraseña actual incorrecta.',
                    icon: 'error',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            }

        } catch (error) {

        }
    };
    return (
        <div className="p-6">
            {!showForm ? (
                <>
                    <h1 className="text-2xl mb-4 font-semibold">Médicos</h1>
                    <hr className="my-4" />
                    <div className="flex flex-col justify-end md:flex-row items-center mb-4 space-y-4 md:space-y-0 md:space-x-4">
                        <input
                            type="text"
                            placeholder="Buscar médicos"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-64 px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleShowForm}
                            className="px-5 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
                        >
                            Crear Médico
                        </button>
                    </div>
                    <hr className="my-4" />
                    <div className="overflow-x-auto">

                        <table className="w-full border-collapse border border-gray-200">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Nombre</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Correo Electrónico</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Número de Celular</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Tipo de ID</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">No. Identificación</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtereddoctors.map((doctor) => (
                                    <tr key={doctor._id}>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.fullName}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.email}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.cellNumber}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.idType}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.idNumber}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">
                                            <button
                                                onClick={() => toggleMenu(doctor)}
                                                className="px-3 py-1 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                                            >
                                                ...
                                            </button>
                                            {selectedDoctor === doctor && menuVisible && (
                                                <div className="mt-2 bg-white shadow-md rounded-md p-2 absolute">
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Perfil')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Editar Perfil
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Calendario')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Calendario
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Agendar Cita')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Agendar Cita
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Cambiar Contraseña')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Cambiar Contraseña
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Eliminar Doctor')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Eliminar Doctor
                                                    </button>

                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <Doctor onGoBack={handleGoBack} fetchDoctors={fetchDoctors} />

            )}



            {showChangePassword && (
                <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50" >
                    <div className="bg-white p-6 rounded-lg shadow-lg" >
                        <h2 className="text-lg font-bold mb-4">Cambiar Contraseña para {selectedDoctor.firstName} {selectedDoctor.firstLastName}</h2>

                        <p className="mb-4 font-bold">Correo electrónico:</p>
                        <p className="mb-4 ">{selectedDoctor.email}</p>

                        <div className="mb-4">
                            <label className="block font-bold mb-2">Nueva Contraseña</label>
                            <input
                                type="password"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-between">
                            <button
                                onClick={() => setShowChangePassword(false)}
                                className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow-md hover:bg-gray-400"
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                                onClick={handleChangePassword}
                            >
                                Cambiar Contraseña
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de Agendar Cita */}
            {showAppointmentModal && (
                <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center  items-center z-50">
                    <div className="bg-white p-6 rounded-lg max-w-xl shadow-lg ">
                        <h2 className="text-lg font-bold mb-4">
                            Agendar Cita para el Doctor {selectedDoctor.fullName}
                        </h2>

                        {/* Servicio del Doctor */}
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Servicio:
                            <input
                                value={selectedDoctor.service}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </label>
                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">
                            Seleccione Paciente:
                            <Select
                                options={patients.map(patient => ({
                                    value: patient._id,
                                    label: `${patient.firstName} ${patient.firstLastName} | Documento | ${patient.idNumber}`
                                }))}
                                onChange={(selectedOption) => setSelectedPatient(selectedOption ? selectedOption.value : '')}
                                className="mt-1 block w-full px-3 py-2"
                                placeholder="Buscar paciente..."
                            />
                        </label>

                        {/* Fecha de la cita */}
                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">
                            Fecha de la cita:
                            <input
                                type="datetime-local"
                                value={appointmentDate}
                                onChange={(e) => setAppointmentDate(e.target.value)}
                                className="mt-1 block w-full px-3 py-2  border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </label>

                        {/* Notas */}
                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">
                            Motivo de la consulta:
                            <textarea
                                value={appointmentNotes}
                                onChange={(e) => setAppointmentNotes(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                rows="4"
                                placeholder="Añadir Motivo de la consulta..."
                            />
                        </label>

                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleCancel}
                                className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow-md hover:bg-gray-400"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleScheduleAppointment}
                                className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                            >
                                Agendar
                            </button>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};



const Doctor = ({ onGoBack, fetchDoctors }) => {
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({
        fullName: '', // Nombre Completo
        idType: '', // Tipo de Documento de Identidad
        idNumber: '', // Número de Documento de Identidad
        cedulaPDF: '', // Documento Identidad (Adjuntar)
        email: '', // Correo Electrónico
        cellNumber: '', // Número de Celular
        diploma_acta_grado: '', // Diploma o Acta de Grado de Pregrado (PDF o Imagen)
        diploma_especializacion: '', // Diploma o Acta de Grado de Especialización
        homologacion_estudios: '', // Homologación de Estudios
        tarjeta_profesional: '', // Tarjeta Profesional (PDF o Imagen)
        rethusNumber: '', // ReThus (Número)
        rethus: '', // ReThus (PDF o Imagen)
        civilLiabilityPolicyNumber: '', // Póliza de Responsabilidad Civil (Número)
        poliza_responsabilidad_civil: '', // Póliza de Responsabilidad Civil (PDF o Imagen)
        service: '', // Servicio para ofrecer en la plataforma
        foto_perfil: '', // Foto profesional digital
        firma_profesional: '', // Firma profesional digital
    });

    useEffect(() => {

        // Fetch departments when modal opens
        const fetchDepartments = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/departments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchDepartments();

    }, []);

    useEffect(() => {
        if (formData.department) {
            // Fetch municipalities when a department is selected
            const fetchCities = async () => {
                if (formData.department) {
                    try {
                        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cities/${formData.department}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setCities(response.data);
                    } catch (error) {
                        console.error('Error fetching cities:', error);
                    }
                }
            };

            fetchCities();
        } else {
            setCities([]);
        }
    }, [formData.department]);

    const handleDepartmentChange = (event) => {
        const selectedId = event.target.value;
        const selectedDepartment = departments.find(dept => dept._id === selectedId);

        setFormData({
            ...formData,
            department: selectedId, // ID del departamento
            departmentName: selectedDepartment ? selectedDepartment.departamento : '', // Nombre del departamento
        });
    };
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
        formDataToSend.append('clinicId', clinicId);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/doctorInfo`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }

            });
            if (response.status >= 200 && response.status < 300) {
                Swal.fire({
                    title: '¡Doctor creado con éxito!',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                }).then(() => {
                    // Ejecutar onGoBack cuando el usuario haga clic en OK
                    onGoBack();
                });
                fetchDoctors();
            } else {
                console.error('Error al guardar los datos:', response.statusText);
            }
        } catch (error) {
            Swal.fire({
                title: 'El correo electronico ya se encuentra en uso',
                icon: 'error',
                customClass: {
                    title: 'swal2-title-custom',
                    htmlContainer: 'swal2-html-container-custom'
                }
            });
        }
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-2xl font-semibold">Agregar Nuevo Profesional del Médico</h2>
                <button
                    type="button"
                    className="px-3 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={onGoBack}
                >
                    Volver
                </button>
            </div>

            <form className="space-y-6" onSubmit={handleSubmit} encType="multipart-form-data">

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                    {/* Nombre Completo */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Nombre Completo *</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Nombre Completo"
                            required
                        />
                    </div>

                    {/* Tipo de Documento de Identidad */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Tipo de Documento de Identidad *</label>
                        <select
                            name="idType"
                            value={formData.idType}
                            onChange={handleSelectChange}
                            className="mt-1 block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione</option>
                            <option value="cc">CC</option>
                            <option value="ti">TI</option>
                            <option value="ce">CE</option>
                            <option value="ps">PS</option>
                            <option value="de">DE</option>
                        </select>
                    </div>

                    {/* Número de Documento de Identidad */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Documento de Identidad *</label>
                        <input
                            type="text"
                            name="idNumber"
                            value={formData.idNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Documento de Identidad"
                            required
                        />
                    </div>

                    {/* Correo Electrónico */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Correo Electrónico *</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Correo Electrónico"
                            required
                        />
                    </div>

                    {/* Número de Celular */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Celular *</label>
                        <input
                            type="text"
                            name="cellNumber"
                            value={formData.cellNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Celular"
                            required
                        />
                    </div>
                    {/* Servicio para ofrecer en la plataforma */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Servicio para ofrecer en la plataforma</label>
                        <select
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                        >
                            <option value="">Seleccione</option>
                            <option value="medicina_general">Medicina General</option>
                            <option value="medicina_familiar">Medicina Familiar</option>
                            {/* Agrega más opciones según sea necesario */}
                        </select>
                    </div>

                    {/* Documento (Adjuntar) */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Documento Identidad (PDF o Imagen)</label>
                        <input
                            type="file"
                            name="cedulaPDF"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* Foto profesional digital */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Foto profesional digital</label>
                        <input
                            type="file"
                            name="foto_perfil"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* Firma del profesional */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Firma del profesional (300px * 150px)</label>
                        <input
                            type="file"
                            name="firma_profesional"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".jpg,.jpeg,.png"
                        />
                    </div>
                    {/* Diploma o Acta de Grado de Pregrado */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Diploma o Acta de Grado de Pregrado (PDF o Imagen)</label>
                        <input
                            type="file"
                            name="diploma_acta_grado"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* Diploma o Acta de Grado de Especialización */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Diploma o Acta de Grado de Especialización (si aplica)</label>
                        <input
                            type="file"
                            name="diploma_especializacion"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* Homologación de Estudios */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Homologación de Estudios (si aplica)</label>
                        <input
                            type="file"
                            name="homologacion_estudios"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* Tarjeta Profesional */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Tarjeta Profesional (PDF o Imagen)</label>
                        <input
                            type="file"
                            name="tarjeta_profesional"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* ReThus */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">ReThus (Número y PDF o Imagen)</label>
                        <input
                            type="text"
                            name="rethusNumber"
                            value={formData.rethusNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número ReThus"
                        />
                        <input
                            type="file"
                            name="rethus"
                            onChange={handleChange}
                            className="mt-2 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* Póliza de Responsabilidad Civil Vigente */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Póliza de Responsabilidad Civil Vigente (Número y PDF o Imagen)</label>
                        <input
                            type="text"
                            name="civilLiabilityPolicyNumber"
                            value={formData.civilLiabilityPolicyNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Póliza"
                        />
                        <input
                            type="file"
                            name="poliza_responsabilidad_civil"
                            onChange={handleChange}
                            className="mt-2 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                </div>

                {/* Botón de Enviar */}
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Agregar Profesional
                    </button>
                </div>
            </form>
        </div>

    );
}

export default DoctorComponent;
