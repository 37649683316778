import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment-timezone';

const HistoriaClinicaForm = ({appointmentId}) => {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const sigCanvas = useRef({});
    const [fechaActual, setFechaActual] = useState('');
    const [fechaActual1, setFechaActual1] = useState('');
    const [maxLength, setMaxLength] = useState(10); // Valor inicial para 'CC'
    const [selectedOption, setSelectedOption] = useState('');
    const [imc, setImc] = useState(0);
    const [enfermedadesOptions, setEnfermedadesOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const formValues = watch();
    const [appointmentInfo, setAppointmentInfo] = useState({
        idType: '',
        idNumber: '',
        dob: '',
        firstLastName: '',
        secondLastName: '',
        firstName: '',
        secondName: '',
        gender: '',
        neighborhood: '',
        email: '',
        cellNumber: '',
        notes: '',
        fullName: '',
        service: '',
        nit:'',
        grupoSanguineo:'',
        rh:'',
        grupoEtnico:'',
        estadoCivil:'',
        escolaridad:'',
        sex:'',
        condicionDesplazamiento:'',
        zonaUbicacion:'',
        clinicName: '',
        cellNumber1: '',
        patientAddress: ''

    });

    
    // Log de los valores del formulario en la consola
    useEffect(() => {
        console.log('Valores del formulario:', formValues);
    }, [formValues]);
    // Función para buscar opciones de CIE-10
    const fetchEnfermedadOptions = async (query) => {
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCie10`,
                {
                    params: { query },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const options = response.data.map((med) => ({
                value: med.Codigo,         // Código CIE-10
                label: med.Nombre,         // Nombre de la enfermedad
                descripcion: med.Descripcion // Descripción para el estado
            }));

            setEnfermedadesOptions(options);
        } catch (error) {
            console.error('Error al obtener las enfermedades:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAppointmentInfo  = async () => {
        try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    
      setAppointmentInfo(response.data);
    } catch (error) {
      console.error('Error al obtener la información de la cita:', error);
    }
    };
    
    useEffect(() => {
    fetchAppointmentInfo();
    }, [appointmentId]);

    useEffect(() => {
        setValue('tipoIdentificacion', appointmentInfo.idType);
        setValue('numeroIdentificacion', appointmentInfo.idNumber);
        setValue('fechaNacimiento', appointmentInfo.dob);
        setValue('primerNombre', appointmentInfo.firstName);
        setValue('segundoNombre', appointmentInfo.secondName);
        setValue('primerApellido', appointmentInfo.firstLastName);
        setValue('segundoApellido', appointmentInfo.secondLastName);
        setValue('genero', appointmentInfo.gender);
        setValue('grupoSanguineo', appointmentInfo.grupoSanguineo);
        setValue('rh', appointmentInfo.rh);
        setValue('barrio', appointmentInfo.neighborhood);
        setValue('cellNumber1', appointmentInfo.cellNumber1);
        setValue('estadoCivil', appointmentInfo.estadoCivil);
        setValue('zonaUbicacion', appointmentInfo.zonaUbicacion);
        setValue('condicionDesplazamiento', appointmentInfo.condicionDesplazamiento);
        setValue('grupoEtnico', appointmentInfo.grupoEtnico);
        setValue('patientAddress', appointmentInfo.patientAddress);
        setValue('correoElectronico', appointmentInfo.email);  
        setValue('telefono1', appointmentInfo.cellNumber);
        setValue('escolaridad', appointmentInfo.escolaridad);
        setValue('sex', appointmentInfo.sex);
        setValue('motivoConsulta', appointmentInfo.notes);
        setValue('profesional', appointmentInfo.fullName);
        setValue('especialidad', appointmentInfo.service);
    }, [appointmentInfo, setValue]);

    const handleDenominacionChange = (selectedOption) => {
        // Asigna el valor seleccionado al campo de diagnostico
        register('diagnostico', { value: selectedOption ? selectedOption.label : null });
    };

    // Observa los cambios en peso y talla
    const peso = watch('pesoKg');
    const talla = watch('tallaCm');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    useEffect(() => {
        if (peso && talla) {
            // Convertir peso y talla a números
            const pesoNum = parseFloat(peso);
            const tallaNum = parseFloat(talla) / 100; // Convertir talla a metros

            // Calcular IMC
            if (tallaNum > 0) { // Asegúrate de que la talla sea mayor que 0 para evitar divisiones por cero
                const imcCalc = pesoNum / (tallaNum * tallaNum);
                setImc(imcCalc.toFixed(2)); // Establecer el IMC calculado con dos decimales
            } else {
                setImc(0); // Restablecer el IMC a 0 si la talla es inválida
            }
        }
    }, [peso, talla]);

    const onSubmit = (data) => {
        console.log("Form Data:", data);
    };

    useEffect(() => {
        const ahora = new Date();
        const year = ahora.getFullYear();
        const month = String(ahora.getMonth() + 1).padStart(2, '0'); // Mes empieza en 0
        const day = String(ahora.getDate()).padStart(2, '0');
        const hours = String(ahora.getHours()).padStart(2, '0');
        const minutes = String(ahora.getMinutes()).padStart(2, '0');

        // Formato para datetime-local: YYYY-MM-DDTHH:mm
        const fechaFormateada = `${year}-${month}-${day}T${hours}:${minutes}`;
        const fechaFormateada1 = `${year}-${month}-${day}`;
        setFechaActual(fechaFormateada);
        setFechaActual1(fechaFormateada1);
    }, []);

    const handleClearSignature = () => {
        sigCanvas.current.clear();
    };

    const handleTipoIdentificacionChange = (e) => {
        const tipo = e.target.value;

        // Definir las longitudes máximas basadas en el tipo de identificación
        switch (tipo) {
            case 'CC':
                setMaxLength(10);
                break;
            case 'CE':
                setMaxLength(6);
                break;
            case 'CD':
            case 'PA':
            case 'SC':
                setMaxLength(16);
                break;
            case 'PE':
                setMaxLength(15);
                break;
            case 'RC':
            case 'TI':
                setMaxLength(11);
                break;
            case 'CN':
                setMaxLength(9);
                break;
            case 'AS':
                setMaxLength(10);
                break;
            case 'MS':
                setMaxLength(12);
                break;
            default:
                setMaxLength(10); // Valor predeterminado si no se selecciona un tipo válido
        }
    };
const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return ''; // Verifica que la fecha sea válida
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
    };

    const translateGender = (gender) => {
        return gender === 'male' ? 'Masculino' : 'Femenino';
      };

    return (
        <div className="max-w-2xl mx-auto bg-white shadow-md p-4 ">
            <h2 className="text-xl font-semibold text-center mb-4">HISTORIA CLINICA MEDICINA PRIMERA VEZ</h2>
            <hr className="mb-4" />

            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Pagador */}



                {/* Fecha y hora de registro */}
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Fecha y hora de registro</label>
                    <input
                        type="datetime-local"
                        max={fechaActual}
                        {...register('fechaRegistro')}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tipo de Actividad</label>
                    <select {...register('tipoActividad')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Seleccione una opción</option>
                        <option value="Primera Vez">Primera Vez</option>
                        <option value="Control">Control</option>
                    </select>
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Pagador</label>
                    <select {...register('pagador')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Seleccione una opción</option>

                        <option value="Particular">Particular</option>
                        <option value="Convenio">Convenio</option>
                    </select>
                </div>

                <hr className="my-4" />

                {/* Datos del Paciente */}
                <h3 className="text-lg font-semibold mb-4">DATOS DEL PACIENTE</h3>

                <div className="flex flex-col mt-4">
            <label className="block text-gray-700">Tipo de Identificación</label>
            <input
                {...register('tipoIdentificacion')}
                type="text"
                value={appointmentInfo.idType.toUpperCase()} // Esto mostrará el valor actual
                readOnly // Si deseas que sea de solo lectura
                className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
            />
        </div>


        <div className="flex flex-col mt-4">
                <label className="block text-gray-700">Número de Identificación</label>
                <input
                    {...register('numeroIdentificacion')}
                    type="text"
                    value={appointmentInfo.documentNumber}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                />
            </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Fecha de nacimiento</label>
                    <input type="text"
                        max={fechaActual1}
                        value={moment(appointmentInfo.dob).format('DD-MM-YYYY')}
                        readOnly
                        {...register('fechaNacimiento')} 
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Grupo sanguíneo</label>
                    <input
                    {...register('grupoSanguineo')}
                    type="text"
                    value={appointmentInfo.grupoSanguineo}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                />
                </div>


                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">RH</label>
                    <input
                    {...register('rh')}
                    type="text"
                    value={appointmentInfo.rh}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                />
                </div>


                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Primer nombre *</label>
                    <input {...register('primerNombre')} 
                    value={appointmentInfo.firstName}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Segundo nombre</label>
                    <input {...register('segundoNombre')} 
                    value={appointmentInfo.secondName}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Primer apellido *</label>
                    <input {...register('primerApellido')} 
                    value={appointmentInfo.firstLastName}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Segundo apellido</label>
                    <input {...register('segundoApellido')} 
                    value={appointmentInfo.secondLastName}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                {/*<div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Edad actual</label>
                    <input {...register('edadActual')} className="w-full p-2 border border-gray-300 rounded" />
                </div>*/}

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Género</label>
                    <input {...register('genero')} 
                    value={translateGender(appointmentInfo.gender)}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded">
                        
                    </input>
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Sexo</label>
                    <input
                    {...register('sex')}
                    type="text"
                    value={appointmentInfo.sex}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                />
                </div>


                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Estado civil</label>
                    <input
                    {...register('estadoCivil')}
                    type="text"
                    value={appointmentInfo.estadoCivil}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                />
                </div>


                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Zona de ubicación</label>
                    <input
                    {...register('zonaUbicacion')}
                    type="text"
                    value={appointmentInfo.zonaUbicacion}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Dirección</label>
                    <input
                    {...register('patientAddress')}
                    type="text"
                    value={appointmentInfo.patientAddress}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Barrio</label>
                    <input {...register('barrio')} 
                    value={appointmentInfo.neighborhood}
                    readOnly
                    className="w-full p-2 border border-gray-300 bg-gray-200 rounded" />
                </div>

                {/*<div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Localidad</label>
                    <input {...register('localidad')} className="w-full p-2 border border-gray-300 rounded" />
                </div>*/}

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Correo electrónico</label>
                    <input type="email" {...register('correoElectronico')} 
                    value={appointmentInfo.email}
                    readOnly
                    className="w-full p-2 border border-gray-300  bg-gray-200 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Teléfono 1 *</label>
                    <input {...register('telefono1')}
                     value={appointmentInfo.cellNumber}
                     readOnly
                     className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>


                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Teléfono 2</label>
                    <input {...register('cellNumber1')}
                     value={appointmentInfo.cellNumber1}
                     readOnly
                     className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Escolaridad</label>
                    <input {...register('escolaridad')}
                     value={appointmentInfo.escolaridad}
                     readOnly
                     className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">
                        Ocupación</label>
                    <input {...register('ocupacion')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Condición de desplazamiento</label>
                    <input {...register('condicionDesplazamiento')}
                     value={appointmentInfo.condicionDesplazamiento}
                     readOnly
                     className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Grupo étnico</label>
                    <input {...register('grupoEtnico')}
                     value={appointmentInfo.grupoEtnico}
                     readOnly
                     className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">
                        Aseguradora
                    </label>
                    <input {...register('aseguradora')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tipo de usuario</label>
                    <select {...register('tipoDeUsuario')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un tipo de usuario</option>
                        <option value="Contributivo">Contributivo</option>
                        <option value="Subsidiado">Subsidiado</option>
                        <option value="Vinculado">Vinculado</option>
                        <option value="Particular">Particular</option>
                        <option value="Otro">Otro</option>
                        <option value="Víctima con afiliación al Régimen Contributivo">Víctima con afiliación al Régimen Contributivo</option>
                        <option value="Víctima con afiliación al Régimen subsidiado">Víctima con afiliación al Régimen subsidiado</option>
                        <option value="Víctima no asegurado">Víctima no asegurado</option>
                    </select>
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tipo de afiliado</label>
                    <select {...register('tipoDeAfiliado')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un tipo de usuario</option>
                        <option value="Cotizante">Cotizante</option>
                        <option value="Beneficiario">Beneficiario</option>
                        <option value="Adicional">Adicional</option>

                    </select>
                </div>

                <hr className="my-4" />

                {/* Datos del Acompañante */}
                <h3 className="text-lg font-semibold mb-4">DATOS DEL ACOMPAÑANTE</h3>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Primer nombre </label>
                    <input {...register('acompanantePrimerNombre')} className="w-full p-2 border border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Segundo nombre</label>
                    <input {...register('acompananteSegundoNombre')} className="w-full p-2 border border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Primer apellido</label>
                    <input {...register('acompanantePrimerApellido')} className="w-full p-2 border border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Segundo apellido</label>
                    <input {...register('acompananteSegundoApellido')} className="w-full p-2 border border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Parentesco</label>
                    <select
                        {...register('parentesco')}
                        onChange={handleOptionChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        <option value="">Selecciona un parentesco</option>
                        <option value="Padre">Padre</option>
                        <option value="Madre">Madre</option>
                        <option value="Hijo/hija">Hijo/hija</option>
                        <option value="Otro cual">Otro</option>
                        <option value="Ninguno">Ninguno</option>
                    </select>
                    {selectedOption === "Otro cual" && (
                        <input
                            {...register('otroParentesco')}
                            type="text"
                            placeholder="Especifica cuál"
                            className="mt-2 w-full p-2 border border-gray-300 rounded"
                        />
                    )}
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Teléfono</label>
                    <input {...register('acompananteTelefono')} className="w-full p-2 border border-gray-300 rounded" />
                </div>

                <hr className="my-4" />

                {/* Servicio */}
                <h3 className="text-lg font-semibold mb-4">SERVICIO</h3>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Modalidad de prestación</label>
                    <select {...register('modalidad')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona una modalidad</option>
                        <option value="Telemedicina: Interactiva">Telemedicina: Interactiva</option>
                        <option value="Telemedicina: No interactiva">Telemedicina: No interactiva</option>
                        <option value="Telemedicina: Telexperticia">Telemedicina: Telexperticia</option>
                    </select>
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Servicio</label>
                    <select {...register('servicio')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un servicio</option>
                        <option value="Medicina General">Medicina General</option>
                        <option value="Medicina Especializada">Medicina Especializada</option>
                        <option value="Medicina Familiar">Medicina Familiar</option>
                        <option value="Rehabilitación">Rehabilitación</option>
                        <option value="Terapia Física">Terapia Física</option>
                        <option value="Terapia ocupacional">Terapia ocupacional</option>
                        <option value="Terapia del lenguaje">Terapia del lenguaje</option>
                    </select>
                </div>
                <hr className="my-4" />
                <div className="flex flex-col mt-4">
                    <h3 className="text-lg font-semibold mb-4">MOTIVO QUE ORIGINA LA ATENCIÓN</h3>
                    <select {...register('motivoAtencion')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un motivo</option>
                        <option value="Accidente de IPS">Accidente de IPS</option>
                        <option value="Accidente de Tránsito">Accidente de Tránsito</option>
                        <option value="Otro tipo de accidente">Otro tipo de accidente</option>
                        <option value="Evento catastrófico">Evento catastrófico</option>
                        <option value="Lesión por agresión">Lesión por agresión</option>
                        <option value="Lesión autoinflingida">Lesión autoinflingida</option>
                        <option value="Maltrato">Maltrato</option>
                        <option value="Enfermedad general">Enfermedad general</option>
                    </select>
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">MOTIVO DE CONSULTA</h3>
                <input {...register('motivoConsulta')} 
                value={appointmentInfo.notes}
                readOnly
                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />

                {/*<hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">ENFERMEDAD ACTUAL</h3>
                <textarea {...register('enfermedadActual')} 
                className="w-full p-2 border border-gray-300 rounded" /> */}

                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">REVISION POR SISTEMAS </h3>

                <table className="w-full border-collapse border border-gray-300 mb-4">
                    <thead>
                        <tr>
                            <th className="border border-gray-300 p-2"></th>
                            <th className="border border-gray-300 p-2">Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">Órganos de los sentidos</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("sistemas.0.descripcion")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Piel y faneras</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("sistemas.1.descripcion")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Cardiopulmonar</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("sistemas.2.descripcion")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Gastrointestinal</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("sistemas.3.descripcion")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Genitourinario</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("sistemas.4.descripcion")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Musculo esquelético</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("sistemas.5.descripcion")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Neurológico</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("sistemas.6.descripcion")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">ANTECEDENTES </h3>
                <table className="w-full border-collapse border border-gray-300 mb-4">
                    <thead>
                        <tr>
                            <th className="border border-gray-300 p-2"></th>
                            <th className="border border-gray-300 p-2">Personal</th>
                            <th className="border border-gray-300 p-2">Alerta/Alergia</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">Patológicos</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.0.personal")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.0.alerta")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Farmacológicos</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.1.personal")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.1.alerta")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Quirúrgicos</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.2.personal")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.2.alerta")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Toxico-alérgicos</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.3.personal")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.3.alerta")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Hospitalarios</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.4.personal")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.4.alerta")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Transfusionales</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.5.personal")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.5.alerta")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Preventivos</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.6.personal")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    {...register("antecedentes.6.alerta")}
                                    type="text"
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="w-full border-collapse border border-gray-300 mb-4">
                    <thead>
                        <tr>
                            <th className="border font-semibold border-gray-300 p-2" colSpan={9}>Ginecoobstétricos</th>
                        </tr>
                        <tr>
                            <th className="border border-gray-300 p-2">G</th>
                            <th className="border border-gray-300 p-2">P</th>
                            <th className="border border-gray-300 p-2">A</th>
                            <th className="border border-gray-300 p-2">C</th>
                            <th className="border border-gray-300 p-2">V</th>
                            <th className="border border-gray-300 p-2">M</th>
                            <th className="border border-gray-300 p-2">FUR</th>
                            <th className="border border-gray-300 p-2">FUP</th>
                            <th className="border border-gray-300 p-2">FPP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.g")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.p")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.a")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.c")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.v")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.m")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.fur")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.fup")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                            <td className="border border-gray-300 p-1 text-xs">
                                <input
                                    type="text"
                                    {...register("antecedentes.ginecoobstetricos.0.fpp")}
                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Planificación
                    </label>
                    <input {...register('planificacion')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Ciclos
                    </label>
                    <input {...register('ciclos')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Familiares
                    </label>
                    <textarea {...register('familiares')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Psicosociales
                    </label>
                    <textarea {...register('psicosociales')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">EXAMEN FISICO
                </h3>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Estado de conciencia</label>
                    <select {...register('estadoDeConciencia')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un estado</option>
                        <option value="Alerta">Alerta</option>
                        <option value="Consiente">Consiente</option>
                        <option value="Orientado">Orientado</option>
                        <option value="Comatoso">Comatoso</option>
                        <option value="Somnolencia (obnubilación)">Somnolencia (obnubilación)</option>
                        <option value="Estupor">Estupor</option>
                        <option value="Delirio">Delirio</option>
                    </select>
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">TAS - mming</label>
                    <input
                        type="number"
                        {...register('tasMming', {
                            min: { value: 60, message: 'El valor mínimo es 60' }, // Mensaje para valor mínimo
                            max: { value: 300, message: 'El valor máximo es 300' } // Mensaje para valor máximo
                        })}
                        className="w-full p-2 border border-gray-300 rounded"
                    />

                    {errors.tasMming && <span className="text-red-500">{errors.tasMming.message}</span>}
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">TAD</label>
                    <input
                        type="number"
                        {...register('tad', {
                            min: { value: 60, message: 'El valor mínimo es 40' }, // Mensaje para valor mínimo
                            max: { value: 300, message: 'El valor máximo es 160' } // Mensaje para valor máximo
                        })}
                        className="w-full p-2 border border-gray-300 rounded"
                    />

                    {errors.tad && <span className="text-red-500">{errors.tad.message}</span>}
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">FC/min</label>
                    <input
                        type="number"
                        {...register('fcMin', {
                            min: { value: 60, message: 'El valor mínimo es 30' }, // Mensaje para valor mínimo
                            max: { value: 300, message: 'El valor máximo es 300' } // Mensaje para valor máximo
                        })}
                        className="w-full p-2 border border-gray-300 rounded"
                    />

                    {errors.fcMin && <span className="text-red-500">{errors.fcMin.message}</span>}
                </div>


                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">FR/min

                    </label>
                    <input {...register('frMin')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                {/*<div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Saturación
                    </label>
                    <input {...register('saturacion')} className="w-full p-2 border border-gray-300 rounded" />
                </div>*/}
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Temperatura
                    </label>
                    <input {...register('temperatura')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Peso Kg</label>
                    <input
                        {...register('pesoKg')}
                        className="w-full p-2 border border-gray-300 rounded"
                        type="number" // Asegúrate de que el tipo de entrada sea numérico
                    />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Talla cms</label>
                    <input
                        {...register('tallaCm')}
                        className="w-full p-2 border border-gray-300 rounded"
                        type="number" // Asegúrate de que el tipo de entrada sea numérico
                    />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">IMC</label>
                    <input
                        {...register('imc')}
                        className="w-full p-2 border border-gray-300 rounded"
                        value={imc} // Establecer el valor del IMC calculado
                        readOnly // Hacer que el campo sea de solo lectura
                    />
                </div>
                <table className="w-full border-collapse border border-gray-300 mb-4">
                    <thead>
                        <tr>
                            <th className="border border-gray-300 p-2">Sistema</th>
                            <th className="border border-gray-300 p-2">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">Cabeza y cuello</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    {...register("examenMedico.cabezaYCuello")}
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Tórax y cardio vascular</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    {...register("examenMedico.toraxCardioVascular")}
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Abdomen</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    {...register("examenMedico.abdomen")}
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Genitales y extremidades</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    {...register("examenMedico.genitalesExtremidades")}
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Neurológico</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    {...register("examenMedico.neurologico")}
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Examen mental</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    {...register("examenMedico.examenMental")}
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">RESULTADOS PARACLÍNICOS

                </h3>
                <div className="flex flex-col mt-4">

                    <textarea {...register('resultadosParaclinicos')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">ANÁLISIS Y PLAN</h3>
                <div className="flex flex-col mt-4">

                    <textarea {...register('analisisyplan')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">DIAGNÓSTICOS</h3>
                <label className="block text-gray-700">CIE-10 (Código / Nombre):
                </label>
                <div className="flex flex-col mt-4">
                    <Select
                        name="codigoenfermedad"
                        onChange={handleDenominacionChange}
                        onInputChange={(inputValue) => {
                            if (inputValue.length > 2) {
                                fetchEnfermedadOptions(inputValue);
                            } else {
                                setEnfermedadesOptions([]); // Limpiar las opciones si el input es corto
                            }
                        }}
                        options={enfermedadesOptions}
                        isLoading={loading}
                        className="mt-1 block w-full"
                        placeholder="Buscar..."
                    />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tipo de diagnóstico
                        principal
                    </label>
                    <select {...register('tipoDeDiagnostico')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un tipo</option>
                        <option value="Impresión diagnóstica"> Impresión diagnóstica</option>
                        <option value="Confirmado nuevo">Confirmado nuevo</option>
                        <option value="Confirmado repetido">Confirmado repetido</option>

                    </select>
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">RECOMENDACIONES</h3>
                <div className="flex flex-col mt-4">

                    <textarea {...register('recomendaciones')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">DATOS DEL PROFESIONAL</h3>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Profesional

                    </label>
                    <input {...register('profesional')} 
                    value={appointmentInfo.fullName}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Especialidad

                    </label>
                    <input {...register('especialidad')} 
                    value={appointmentInfo.service}
                    readOnly
                    className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Registro médico

                    </label>
                    <input {...register('registroMedico')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                {/* Firma */}
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Firma</label>
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor="black"
                        canvasProps={{ className: 'border border-gray-300 w-full h-40' }}
                    />
                    <button
                        type="button"
                        className="mt-2 p-2 bg-gray-500 text-white rounded"
                        onClick={handleClearSignature}
                    >
                        Limpiar firma
                    </button>
                </div>

                <hr className="my-4" />

                {/* Botón para enviar */}
                <div className="text-center">
                    <button
                        type="submit"
                        className="p-3 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded w-full"
                    >
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default HistoriaClinicaForm;
