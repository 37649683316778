import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'; 
import Swal from 'sweetalert2'; 
import Select from 'react-select';


const AyudasDiagnosticas = ({ appointmentId }) => {
  const [appointmentInfo, setAppointmentInfo] = useState(null); 
  const [enfermedadesOptions, setEnfermedadesOptions] = useState([]); 
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [cupsOptions, setCupsOptions] = useState([]); 
  const [searchQueryCups, setSearchQueryCups] = useState('');


  const [diagnostico, setDiagnostico] = useState({
    cie10: null,
    descripcionDiagnostico: '',
  });

  const fetchAppointmentInfo  = async () => {
    try {
  const token = localStorage.getItem('token');
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  setAppointmentInfo(response.data);
} catch (error) {
  console.error('Error al obtener la información de la cita:', error);
}
};

useEffect(() => {
fetchAppointmentInfo();
}, [appointmentId]);

const [examenes, setExamenes] = useState([]);

  const handleChangeDiagnostico = (selectedOption) => {
    setDiagnostico({ ...diagnostico, cie10: selectedOption });
  };

  const validateExamenes = () => {
    for (const examen of examenes) {
      if (!examen.codigo || !examen.descripcion || !examen.cantidad || !examen.observacion) {
        return false;
      }
    }
    return true;
  };

  const handleChangeDescripcion = (e) => {
    setDiagnostico({ ...diagnostico, descripcionDiagnostico: e.target.value });
  };

  const handleExamenChange = (index, field, value) => {
    const updatedExamenes = [...examenes];
    updatedExamenes[index][field] = value;
    setExamenes(updatedExamenes);
  };
  const addExamen = () => {
    setExamenes([...examenes, { codigo: '', descripcion: '', cantidad: '', observacion: '' }]);
  };

  const removeExamen = (index) => {
    const updatedExamenes = examenes.filter((_, i) => i !== index);
    setExamenes(updatedExamenes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!diagnostico.cie10) {
      Swal.fire({
        title: 'Error',
        text: 'El campo CIE-10 Es obligatorio.',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    if (examenes.length === 0) {
      Swal.fire('Error', 'Debe agregar al menos un examen.', 'error');
      return;
    }

    if (!validateExamenes()) {
      Swal.fire('Error', 'Debe completar todos los campos de los exámenes.', 'error');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const clinicId = localStorage.getItem('clinicId');
  
      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/pdf/generateAyudasDiagnosticasPDF`,
        method: 'POST',
        data: {
          clinicId: clinicId,
          appointmentId, 
          patientInfo: {
            patientId: appointmentInfo.patientId,
            firstName: appointmentInfo.firstName,
            secondName: appointmentInfo.secondName,
            firstLastName: appointmentInfo.firstLastName,
            secondLastName: appointmentInfo.secondLastName,
            documentNumber: appointmentInfo.documentNumber,
            dob: appointmentInfo.dob,
            idType: appointmentInfo.idType,
            gender: appointmentInfo.gender,
            cellNumber: appointmentInfo.cellNumber,
          },
          diagnostico, 
          examenes,    
          doctorInfo: {
            doctorId: appointmentInfo.doctorId,
            fullName: appointmentInfo.fullName,
            service: appointmentInfo.service,
            idNumber: appointmentInfo.idNumber,
            cellNumber: appointmentInfo.cellNumber,
            notes: appointmentInfo.notes,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });
  
      // Crear un enlace y descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ayudas-diagnosticas-${appointmentId}.pdf`); // Nombre del archivo PDF
      document.body.appendChild(link);
      link.click();
      link.remove();
  
      Swal.fire({
        title: '¡PDF Generado!',
        text: 'El archivo PDF ha sido generado y descargado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar',
      });
    } catch (error) {
      console.error('Error al generar el PDF de exámenes de laboratorio:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al generar el PDF.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };
  
  
  const fetchCups = async (query) => {
    setLoading(true);
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCups`,
        {
          params: { query },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      const options = response.data.map((cup) => ({
        value: cup.Codigo,
        label: cup.Nombre,
        descripcion: cup.Descripcion
      }));
      setCupsOptions(options);
    } catch (error) {
      console.error('Error al obtener la lista de CUPS:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEnfermedadOptions = async (query) => {
    setLoading(true);

try {
  const token = localStorage.getItem('token');
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCie10`,
    {
      params: { query },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log('Response:', response.data);
  const options = response.data.map((med) => ({
    value: med.Codigo,  // Usamos Icd10Code como valor
    label: med.Nombre,
    descripcion: med.Descripcion
  }));
  setEnfermedadesOptions(options);
} catch (error) {
  console.error('Error al obtener la lista de medicamentos:', error);
} finally {
  setLoading(false);
}
};

const handleSearchQueryChangeCups = async (inputValue) => {
  setSearchQueryCups(inputValue);
  if (inputValue.length > 2) {
    await fetchCups(inputValue);
  } else {
    setCupsOptions([]);
  }
};


const handleCupsChange = (index, selectedOption) => {
  const updatedExamenes = [...examenes];
  if (selectedOption) {
    updatedExamenes[index] = {
      ...updatedExamenes[index],
      codigo: selectedOption.value,
      descripcion: selectedOption.label,
    };
  } else {
    updatedExamenes[index] = {
      ...updatedExamenes[index],
      codigo: '',
      descripcion: '',
    };
  }
  setExamenes(updatedExamenes);
};

const handleSearchQueryChange = async (inputValue) => {
setSearchQuery(inputValue);
if (inputValue.length > 2) {
  await fetchEnfermedadOptions(inputValue);
} else {
  setEnfermedadesOptions([]);
}
};

const handleDenominacionChange = (selectedOption) => {
  setDiagnostico((prevState) => ({
    ...prevState,
    cie10: selectedOption ? {
      codigo: selectedOption.value, // Código (A01.2, por ejemplo)
      descripcion: selectedOption.label // Título de la enfermedad
    } : null,
    descripcionDiagnostico: selectedOption ? selectedOption.descripcion : '' // Actualiza la descripción automáticamente
  }));
};


  

  return (
    <div className="h-[400px] overflow-y-auto p-4"> {/* Añadido contenedor con scroll */}
      <h2 className="text-center font-bold bg-blue-600 p-2 text-white text-lg">Ayuda Diagnóstica</h2>
      <hr className="my-4" />
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">CIE-10 (Código / Nombre):</label>
        <Select
          name="codigoenfermedad"
          value={enfermedadesOptions.find(option => option.value === diagnostico.cie10?.value)}
          onChange={handleDenominacionChange}
          onInputChange={(inputValue) => {
            handleSearchQueryChange(inputValue);
          }}
          options={enfermedadesOptions}
          isLoading={loading}
          className="mt-1 block w-full"
          placeholder="Buscar Enfermedad..."
        />
      </div>

      {/* Descripción del diagnóstico */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-1">Descripción del Diagnóstico:</label>
        <input
          name="descripcionDiagnostico"
          value={diagnostico.descripcionDiagnostico}
          onChange={handleChangeDescripcion}
          className="w-full border p-2 border-gray-300 rounded-md shadow-sm"
          placeholder="Descripción del diagnóstico"
        />
      </div>

      {/* Tabla de Exámenes */}
      <h3 className="text-lg font-semibold mb-4">Listado de Exámenes</h3>

      <div className="space-y-4">
  {examenes.map((examen, index) => (
    <div key={index} className="bg-white shadow-md rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Examen {index + 1}</h3>
        <button
          type="button"
          onClick={() => removeExamen(index)}
          className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
        >
          Eliminar
        </button>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Código / Nombre CUPS:</label>
        <Select
          name="codigocups"
          value={cupsOptions.find(option => option.value === examen.codigo)}
          onChange={(selectedOption) => handleCupsChange(index, selectedOption)}
          onInputChange={(inputValue) => {
            handleSearchQueryChangeCups(inputValue);
          }}
          options={cupsOptions}
          isLoading={loading}
          className="mt-1 block w-full"
          placeholder="Buscar cups..."
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Descripción:</label>
        <input
          type="text"
          name="descripcion"
          value={examen.descripcion}
          onChange={(e) => handleExamenChange(index, 'descripcion', e.target.value)}
          className="w-full border p-2 border-gray-300 rounded-md"
          placeholder="Descripción"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Cantidad:</label>
        <input
          type="number"
          name="cantidad"
          value={examen.cantidad}
          onChange={(e) => handleExamenChange(index, 'cantidad', e.target.value)}
          className="w-full border p-2 border-gray-300 rounded-md"
          placeholder="Cantidad"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Observación:</label>
        <input
          type="text"
          name="observacion"
          value={examen.observacion}
          onChange={(e) => handleExamenChange(index, 'observacion', e.target.value)}
          className="w-full border p-2 border-gray-300 rounded-md"
          placeholder="Observación"
        />
      </div>
    </div>
  ))}
</div>


      <button
        type="button"
        onClick={addExamen}
        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-600 mb-4"
      >
        Añadir
      </button>

      <button
        type="submit"
        onClick={handleSubmit}
        className="w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white py-2 rounded-md hover:bg-blue-700"
      >
        Enviar
      </button>
    </div>
  );
};

export default AyudasDiagnosticas;
