import React, { useState } from "react";
import PatientDoctorDocumentsTable from "./PatientDoctorDocumentsTable";

const DoctorAssignedDocuments = () => {
    const [doctorId, setDoctorId] = useState(localStorage.getItem('doctorId'));

    return (
        <div className="m-3">
            <PatientDoctorDocumentsTable doctorId={doctorId} isDownload={true} />
        </div>
    )
}

export default DoctorAssignedDocuments;