import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { alertaGeneral } from '../../utils/alertas';
import { onHandleClickDownload } from '../ClinicComponent/TableDocuments';
import DocumentsInAppointments from './DocumentsInAppointments';

const PatientDoctorDocumentsTable = ({ patientId = null, doctorId = null, documentsInAppointment = null, isDownload = false, appoinmentActual = null, showAppointments = true }) => {
    const [filtroDocumentos, setFiltroDocumentos] = useState('medicamentos');
    const [incapacidades, setIncapacidades] = useState(null);
    const [medicamentos, setMedicamentos] = useState(null);
    const [examenesLaboratorio, setExamenesLaboratorio] = useState(null);
    const [ayudasDiagnosticas, setAyudasDiagnosticas] = useState(null);
    const [interconsultas, setInterconsultas] = useState(null);
    const [apoyoTerapeutico, setApoyoTerapeutico] = useState(null);
    const [documentos, setDocumentos] = useState(null);
    const [activeTab, setActiveTab] = useState('all_documents');
    const [showDocumentsAppointments, setShowDocumentsAppointments] = useState(false);

    const petitionAxios = patientId ? `${process.env.REACT_APP_BACKEND_URL}/api/doctor/getDocumentsPatient/${patientId}` :
        doctorId ? `${process.env.REACT_APP_BACKEND_URL}/api/doctor/getDocumentsAsigned/${doctorId}`
            : '';

    console.log(documentos);

    useEffect(() => {
        if (documentsInAppointment != null) {
            setIncapacidades(documentsInAppointment.incapacidad);
            setMedicamentos(documentsInAppointment.medicamentos);
            setExamenesLaboratorio(documentsInAppointment.examenLaboratorio);
            setAyudasDiagnosticas(documentsInAppointment.ayudasDiagnosticas);
            setInterconsultas(documentsInAppointment.interconsulta);
            setApoyoTerapeutico(documentsInAppointment.apoyoTerapeutico);

            setDocumentos(documentsInAppointment);
            return
        }; // Si ya se cargaron los documentos de las citas médicas, no se hace la petición

        if (petitionAxios === '') return;

        const token = localStorage.getItem('token');
        axios.get(petitionAxios, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            if (res) {
                console.log(res.data);
                setIncapacidades(res.data.incapacidad);
                setMedicamentos(res.data.medicamentos);
                setExamenesLaboratorio(res.data.examenLaboratorio);
                setAyudasDiagnosticas(res.data.ayudasDiagnosticas);
                setInterconsultas(res.data.interconsulta);
                setApoyoTerapeutico(res.data.apoyoTerapeutico);
            }
        }).catch((error) => {
            alertaGeneral({
                titulo: 'Error',
                messageHtml: 'Hubo un problema al obtener los documentos del paciente. Por favor, inténtalo de nuevo.',
                isError: true
            })
            console.error('Error al obtener los documentos del paciente:', error);
        })

    }, [patientId, doctorId, documentsInAppointment]);

    useEffect(() => {
        switch (filtroDocumentos) {
            case 'medicamentos':
                setDocumentos(medicamentos && medicamentos);
                break;
            case 'incapacidad':
                setDocumentos(incapacidades && incapacidades);
                break;
            case 'examenes':
                setDocumentos(examenesLaboratorio && examenesLaboratorio);
                break;
            case 'ayudas':
                setDocumentos(ayudasDiagnosticas && ayudasDiagnosticas);
                break;
            case 'interconsultas':
                setDocumentos(interconsultas && interconsultas);
                break;
            case 'apoyo':
                setDocumentos(apoyoTerapeutico && apoyoTerapeutico);
                break;
            default:
                setDocumentos(null);
                break;
        }
    }, [filtroDocumentos, medicamentos, incapacidades, examenesLaboratorio, ayudasDiagnosticas, interconsultas, apoyoTerapeutico])

    const onHandleClickView = (url) => {
        window.open(url, '_blank');
    }
    // console.log(documentsInAppointment)

    if ((!incapacidades || !medicamentos || !examenesLaboratorio || !ayudasDiagnosticas || !interconsultas || !apoyoTerapeutico) && !documentsInAppointment) {
        return (
            <div className="p-4 bg-white shadow-lg rounded-md">
                <p className="text-gray-700 font-bold ml-3 uppercase"> Cargando documentos <i className="ml-1 text-black fas fa-spinner fa-pulse" /> </p>
            </div>
        )
    }

    if (documentsInAppointment && Object.entries(documentsInAppointment).length === 0) {
        return (
            <div className="p-4 bg-white shadow-lg rounded-md">
                <p className="text-gray-700 font-bold ml-3 uppercase"> No hay documentos para mostrar </p>
            </div>
        )
    }


    return (
        <>
            <div className="flex-grow py-8 px-4 sm:px-6 lg:px-8">
                {showAppointments && (
                    <>
                        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
                            Documentos {patientId ? 'del Paciente' : 'Asignados a Pacientes'}
                        </h1>
                        <div className="bg-white rounded-lg shadow overflow-hidden">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex" aria-label="Tabs">
                                    <button
                                        onClick={() => setActiveTab('appointments')}
                                        className={`w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm ${activeTab === 'appointments'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                            }`}
                                    >
                                        Ver por citas
                                    </button>
                                    <button
                                        onClick={() => setActiveTab('all_documents')}
                                        className={`w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm ${activeTab === 'all_documents'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                            }`}
                                    >
                                        Ver todos los documentos
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </>
                )}


                {/* INICIO DIFERENTES COMPONENTES */}
                {activeTab === 'all_documents' && (
                    <div className="bg-white p-6 shadow-md rounded-lg">
                        {/* Contenedor scrollable con borde inferior */}
                        <div className="relative mb-4">
                            <div className="overflow-x-auto scrollbar-hide">
                                {/* Encabezado de pestañas */}
                                <div className="flex whitespace-nowrap pb-2">
                                    <button
                                        className={`px-4 py-2 focus:outline-none ${filtroDocumentos === 'medicamentos' ? 'border-b-2 border-blue-600' : ''}`}
                                        onClick={() => setFiltroDocumentos('medicamentos')}
                                    >
                                        Medicamentos
                                    </button>
                                    <button
                                        className={`px-4 py-2 focus:outline-none ${filtroDocumentos === 'incapacidad' ? 'border-b-2 border-blue-600' : ''}`}
                                        onClick={() => setFiltroDocumentos('incapacidad')}
                                    >
                                        Incapacidad
                                    </button>
                                    <button
                                        className={`px-4 py-2 focus:outline-none ${filtroDocumentos === 'examenes' ? 'border-b-2 border-blue-600' : ''}`}
                                        onClick={() => setFiltroDocumentos('examenes')}
                                    >
                                        Exámenes de Laboratorio
                                    </button>
                                    <button
                                        className={`px-4 py-2 focus:outline-none ${filtroDocumentos === 'ayudas' ? 'border-b-2 border-blue-600' : ''}`}
                                        onClick={() => setFiltroDocumentos('ayudas')}
                                    >
                                        Ayudas Diagnósticas
                                    </button>
                                    <button
                                        className={`px-4 py-2 focus:outline-none ${filtroDocumentos === 'interconsulta' ? 'border-b-2 border-blue-600' : ''}`}
                                        onClick={() => setFiltroDocumentos('interconsulta')}
                                    >
                                        Interconsulta
                                    </button>
                                    <button
                                        className={`px-4 py-2 focus:outline-none ${filtroDocumentos === 'apoyo' ? 'border-b-2 border-blue-600' : ''}`}
                                        onClick={() => setFiltroDocumentos('apoyo')}
                                    >
                                        Apoyo Terapéutico
                                    </button>
                                </div>
                            </div>
                            {/* Borde inferior que se extiende completamente */}
                            <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gray-300" />
                        </div>
                        <div>
                            {(documentos && documentos.length > 0) ?
                                <div className="overflow-x-auto max-w-full">
                                    <div className="inline-block min-w-full">
                                        <table className="min-w-full divide-y border border-gray-300 divide-gray-300 border-collapse rounden-lg">
                                            <thead className="bg-gray-200 text-center">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">
                                                        Fecha Asignación {filtroDocumentos}
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">
                                                        {patientId || (documentsInAppointment && documentsInAppointment) ? 'Doctor Encargado' : 'Paciente'}
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">
                                                        Acciones
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-300">
                                                {documentos && documentos.map((documento, index) => (
                                                    <tr key={index} className="divide-x divide-darkgray-200">
                                                        <td className="px-4 py-2">
                                                            <p className="text-sm text-wrap text-center font-medium text-gray-500"> {new Date(documento.createdAt).toLocaleDateString()} </p>
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            <p className="text-sm text-wrap text-center font-medium text-gray-500">
                                                                {patientId || (documentsInAppointment && documentsInAppointment) ? documento?.doctorId?.fullName : documento?.patientId?.fullName}
                                                            </p>
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                                                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded" onClick={() => onHandleClickView(documento.url)}> Ver </button>
                                                                {isDownload &&
                                                                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded" onClick={() => onHandleClickDownload(documento.url, (filtroDocumentos + '_' + documento?.patientId?.fullName))}> Descargar </button>
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                <h1 className="text-center mb-4 uppercase text-gray-500"> <b> No hay {filtroDocumentos} para mostrar </b> </h1>
                            }
                        </div>
                    </div>
                )}

                {activeTab === 'appointments' && showAppointments && (
                    <div className="overflow-x-auto max-w-full">
                        <DocumentsInAppointments patientId={patientId && patientId} doctorId={doctorId && doctorId} />
                    </div>
                )}
            </div>
        </>
    )
}

export default PatientDoctorDocumentsTable;
