import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import logo from '../../images/Logo_Mozart_color.png';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import '../../App.css';
import '../../styles/Patient.css';

const Doctor = ({ setActiveComponent }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Estado para el menú desplegable en pantallas grandes
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [imageProfile, setImageProfile] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    const storedDoctorName = localStorage.getItem('doctorName');
    const storedEmail = localStorage.getItem('doctorEmail');
    const storedImage = localStorage.getItem('imageProfile');
    if (storedDoctorName) {
      setDoctorName(storedDoctorName);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
    if (storedImage) {
      setImageProfile(storedImage);
    }
  }, []);

  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('imageProfile');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('clinicName');
    localStorage.removeItem('clinicEmail');
    localStorage.removeItem('clinicId');
    localStorage.removeItem('patientName');
    localStorage.removeItem('patientEmail');
    localStorage.removeItem('patientId');
    localStorage.removeItem('doctorName');
    localStorage.removeItem('doctorEmail');
    localStorage.removeItem('doctorId');
    localStorage.removeItem('adminName');
    localStorage.removeItem('adminEmail');
    localStorage.removeItem('adminId');
    navigate('/');
  };

  const handleChangePassword = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/changePassword`, {
        username: email,
        currentPassword: currentPassword,
        newPassword: newPassword
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 200) {
        Swal.fire({
          title: 'Contraseña cambiada con exito!.',
          icon: 'success',
          customClass: {
            title: 'swal2-title-custom',
            htmlContainer: 'swal2-html-container-custom'
          }
        });

        closeChangePasswordModal();
      } else {
        Swal.fire({
          title: 'Contraseña actual incorrecta.',
          icon: 'error',
          customClass: {
            title: 'swal2-title-custom',
            htmlContainer: 'swal2-html-container-custom'
          }
        });
      }

    } catch (error) {
      Swal.fire({
        title: 'Contraseña actual incorrecta.',
        icon: 'error',
        customClass: {
          title: 'swal2-title-custom',
          htmlContainer: 'swal2-html-container-custom'
        }
      });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-black p-4 flex flex-col md:flex-row justify-between items-center shadow-md">
      <div className="flex items-center cursor-pointer" onClick={() => setActiveComponent('bienvenida')}>
        <img src={logo} alt="Logo" className="h-14 mr-4 shadow-lg border-2 border-gray-800 bg-white" />
      </div>

      {/* Opciones del menú para pantallas grandes */}
      <nav className="hidden md:flex flex-1 md:flex-row md:space-x-4">
        <div className="flex flex-grow space-x-4">
          <button
            className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
            onClick={() => setActiveComponent('control')}
          >
            Tablero de Control
          </button>
          <button
            className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
            onClick={() => setActiveComponent('perfil')}
          >
            Mi perfil
          </button>
          <button
            className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
            onClick={() => setActiveComponent('calendario')}
          >
            Calendario
          </button>
          <button
            className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
            onClick={() => setActiveComponent('documentos_asignados')}
          >
            Documentos Asignados
          </button>
        </div>


        <div className="relative flex items-center">

          {imageProfile &&
            <div className="container-image-profile-user mr-5" >
              <img src={imageProfile} alt="Profile" width="50" height="50" className="img-profile-user" />
            </div>
          }

          <button
            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white py-2 px-4 rounded"
            onClick={toggleDropdown}
          >
            ☰
          </button>
          <div className={`absolute right-0 top-full mt-2 w-48 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 rounded-lg shadow-lg ${isDropdownOpen ? 'block' : 'hidden'}`}>
            <nav className="flex flex-col p-2">
              <button
                className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
                onClick={openChangePasswordModal}
              >
                Cambiar Contraseña
              </button>
              <button
                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white py-2 px-4 rounded mt-2"
                onClick={handleLogout}
              >
                Cerrar Sesión
              </button>
            </nav>
          </div>
        </div>
      </nav>
      <Modal
        isOpen={isChangePasswordModalOpen}
        onRequestClose={closeChangePasswordModal}
        contentLabel="Cambiar Contraseña"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-xl font-bold mb-4">Cambiar Contraseña</h2>
        <p className="mb-4 font-bold">Correo electrónico:</p>
        <p className="mb-4">{email}</p>
        <div className="mb-4">
          <label className="block font-bold mb-2">Contraseña Actual</label>
          <input
            type="password"
            className="w-full p-2 border border-gray-300 rounded"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Nueva Contraseña</label>
          <input
            type="password"
            className="w-full p-2 border border-gray-300 rounded"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="flex justify-between">
          <button className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700" onClick={closeChangePasswordModal}>
            Cancelar
          </button>
          <button className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700" onClick={handleChangePassword}>
            Cambiar Contraseña
          </button>
        </div>
      </Modal>

      <style jsx>{`
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          position: relative;
          z-index: 1050; /* Asegúrate de que el modal esté encima de otros elementos */
          max-width: 500px; /* Ajusta el ancho máximo del modal */
          margin: 0 auto; /* Centra el modal horizontalmente */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          z-index: 1040; /* Asegúrate de que el overlay esté debajo del modal */
          display: flex;
          align-items: center;
          justify-content: center; /* Centra el modal verticalmente */
        }
      `}</style>
      {/* Menú desplegable para pantallas pequeñas */}
      <div className="flex md:hidden relative">
        {imageProfile &&
          <div className="container-image-profile-user mr-5" >
            <img src={imageProfile} alt="Profile" width="50" height="50" className="img-profile-user" />
          </div>
        }
        <button
          className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
          onClick={toggleMenu}
        >
          ☰
        </button>
        <div className={`absolute right-0 top-14 mt-2 w-48 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 rounded-lg shadow-lg ${isMenuOpen ? 'block' : 'hidden'}`} style={{ right: '-15px' }}>
          <nav className="flex flex-col p-2">
            <button
              className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
              onClick={() => setActiveComponent('control')}
            >
              Tablero de Control
            </button>
            <button
              className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
              onClick={() => {
                setIsDropdownOpen(false);
                setActiveComponent('perfil');
              }}
            >
              Mi perfil
            </button>
            <button
              className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
              onClick={() => {
                setIsDropdownOpen(false);
                setActiveComponent('calendario');
              }}
            >
              Calendario
            </button>
            <button
              className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
              onClick={openChangePasswordModal}
            >
              Cambiar Contraseña
            </button>
            <button
              className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white py-2 px-4 rounded mt-2"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </nav>
        </div>
      </div>
    </header>

  );
};

export default Doctor;
