import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from 'react-select';

const Incapacidad = ({ appointmentId }) => {
  const [appointmentInfo, setAppointmentInfo] = useState(null); // Cambiado a appointmentInfo para englobar todos los datos
  const [cities, setCities] = useState([]);
  const today = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    lugarExpedicion: '',
    fechaExpedicion: today,
    esProrroga: false,
    modalidadPrestacionServicio: '',
    fechaInicial: '',
    dias: '',
    fechaFinal: '',
    causaAtencion: '',
    diagnosticoPrincipal: '',
  });
  useEffect(() => {
    if (appointmentInfo) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        modalidadPrestacionServicio: appointmentInfo.service || "", // Aquí inicializamos con appointmentInfo.service
      }));
    }
  }, [appointmentInfo]);

  useEffect(() => {
    // Llamada al backend para obtener las ciudades
    const fetchCities = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/clinic/ciudades`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCities(response.data.cities.map(city => ({ label: city, value: city })));
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  useEffect(() => {
    if (formData.fechaInicial && formData.dias) {
      const fechaFinalCalculada = moment(formData.fechaInicial)
        .add(formData.dias, "days")
        .format("YYYY-MM-DD");
      setFormData((prevData) => ({
        ...prevData,
        fechaFinal: fechaFinalCalculada,
      }));
    }
  }, [formData.fechaInicial, formData.dias]);


  const fetchAppointmentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAppointmentInfo(response.data);
    } catch (error) {
      console.error('Error al obtener la información de la cita:', error);
    }
  };

  useEffect(() => {
    fetchAppointmentInfo();
  }, [appointmentId]);

  if (!appointmentInfo) {
    return <div className="p-4 bg-white shadow-lg rounded-md">Cargando información...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const clinicId = localStorage.getItem('clinicId');
      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/pdf/generateIncapacidadPDF`,
        method: 'POST',
        data: {
          clinicId: clinicId,
          appointmentId,
          patientInfo: {
            patientId: appointmentInfo.patientId,
            firstName: appointmentInfo.firstName,
            secondName: appointmentInfo.secondName,
            firstLastName: appointmentInfo.firstLastName,
            secondLastName: appointmentInfo.secondLastName,
            documentNumber: appointmentInfo.documentNumber,
            dob: appointmentInfo.dob,
            idType: appointmentInfo.idType,
            gender: appointmentInfo.gender,
            cellNumber: appointmentInfo.cellNumber,
          },
          formData,
          doctorInfo: {
            doctorId: appointmentInfo.doctorId,
            fullName: appointmentInfo.fullName,
            service: appointmentInfo.service,
            idNumber: appointmentInfo.idNumber,
            cellNumber: appointmentInfo.cellNumber,
            notes: appointmentInfo.notes,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob', // Esto es correcto para manejar archivos binarios
      });

      // Crear un enlace y descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `incapacidad-${appointmentId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();


      Swal.fire({
        title: '¡PDF Generado!',
        text: 'El archivo PDF ha sido generado y descargado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      console.error('Error al enviar los datos de incapacidad:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al generar el PDF.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };




  return (
    <div className="h-[400px] overflow-y-auto p-4">
      <div className="text-center font-bold bg-blue-600 p-2 text-white text-lg">
        <h1> Datos Incapacidad</h1>
      </div>
      <hr className="my-4" />
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Lugar de Expedición:
          </label>
          <Select
            options={cities}
            placeholder="Seleccione la ciudad de expedición"
            isSearchable
            required
            onChange={(selectedOption) => {
              setFormData({
                ...formData,
                lugarExpedicion: selectedOption.value, // Guardar el valor seleccionado en el campo lugarExpedicion
              });
            }}
          />

        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Fecha de Expedición:
          </label>
          <input
            type="date"
            readOnly
            name="fechaExpedicion"
            value={formData.fechaExpedicion}
            onChange={handleChange}
            className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />

        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            ¿Es Prórroga?
          </label>
          <input
            type="checkbox"
            name="esProrroga"
            checked={formData.esProrroga}
            onChange={handleChange}
            className="mt-1"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Modalidad de la Prestación del Servicio:
          </label>
          <input
            type="text"
            name="modalidadPrestacionServicio"
            value={formData.modalidadPrestacionServicio}
            readOnly
            className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Fecha Inicial:
          </label>
          <input
            type="date"
            name="fechaInicial"
            required
            value={formData.fechaInicial}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Días:
          </label>
          <input
            type="number"
            name="dias"
            required
            value={formData.dias}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm"
            placeholder="Número de días"
          />
        </div>

        <div>
        <label className="block text-sm font-medium text-gray-700">
          Fecha Final:
        </label>
        <input
          type="date"
          name="fechaFinal"
          value={formData.fechaFinal}
          onChange={handleChange}
          readOnly
          className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />          
        
      </div>

        

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Diagnóstico Principal:
          </label>
          <input
            type="text"
            name="diagnosticoPrincipal"
            value={formData.diagnosticoPrincipal}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm"
            placeholder="Diagnóstico principal"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
           Observación de la Incapacidad:
          </label>
          <textarea
            name="causaAtencion"
            value={formData.causaAtencion}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm"
            placeholder="Descripción de la incapacidad"
          />
        </div>  
        <button
          type="submit"
          className="mt-4 w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white py-2 rounded-md hover:bg-blue-700"
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default Incapacidad;
