import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';


const UpcomingPatientAppointments = () => {
    const [appointments, setAppointments] = useState([]);
    const [error, setError] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null); 
    const [allAppointments, setAllAppointments] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false); // Nuevo estado para el modal de motivo
    const [cancelReason, setCancelReason] = useState(''); 
    const [activeTab, setActiveTab] = useState('agendadas');

    const fetchAppointments = async () => {
        try {
            const patientId = localStorage.getItem('patientId');
            const token = localStorage.getItem('token');

            if (!patientId || !token) {
                throw new Error('Falta información de autenticación');
            }

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getUpcomingAppointmentsPatient/${patientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const appointmentsData = response.data
                .filter(appointment => appointment.estado === 'Agendada')
                .map(appointment => ({
                    title: 'Cita de control',
                    date: moment.utc(appointment.date),
                    doctorName: appointment.doctor.fullName,
                    notes: appointment.notes,
                    appointmentId: appointment._id,
                }));

            setAppointments(appointmentsData);
            setError(null);
        } catch (error) {
            console.error('Error fetching appointments:', error);
            setError(error.message || 'Hubo un error al cargar las citas');
        }
    };

    const fetchAllAppointments = async () => {
        try {
            const patientId = localStorage.getItem('patientId');
            const token = localStorage.getItem('token');

            if (!patientId || !token) {
                throw new Error('Falta información de autenticación');
            }

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAllAppointmentsPatient/${patientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setAllAppointments(response.data);
            setError(null);
        } catch (error) {
            console.error('Error fetching all appointments:', error);
            setError(error.message || 'Hubo un error al cargar todas las citas');
        }
    };

    useEffect(() => {
        fetchAppointments();
        fetchAllAppointments();
    }, []);

    const updateAllAppointments = async () => {
        try {
            await Promise.all([fetchAppointments(), fetchAllAppointments()]);
        } catch (error) {
            console.error('Error al actualizar citas:', error);
        }
    };

    const getTimeLeft = (appointmentDate) => {
        const nowLocal = moment();
        const appointmentMoment = moment(appointmentDate);
        
        const duration = moment.duration(appointmentMoment.diff(nowLocal)); 
        
        let hours = Math.floor(duration.asHours() + 5);
        let minutes = duration.minutes() + 60;
        
        if (minutes >= 60) {
            hours += Math.floor(minutes / 60);
            minutes = minutes % 60;
        }
        
        return `${hours} horas y ${minutes} minutos`;
    };

    const handleVideoCall = (appointmentId) => {
        window.location.href = `/video-call/${appointmentId}`;
    };

    const handleCancel = (appointmentId) => {
        setSelectedAppointment(appointmentId);
        setShowConfirmationModal(true);
    };

    const confirmCancel = () => {
        setShowConfirmationModal(false);
        setShowReasonModal(true); // Mostrar modal para ingresar el motivo
    };

    const confirmCancelWithReason = async () => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cancelAppointment/${selectedAppointment}`, {
                motivoCancelacion: cancelReason,
                canceladoPor: role,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            fetchAppointments();
            setShowReasonModal(false);
            setCancelReason('');
            Swal.fire({
                icon: 'success',
                title: 'Cita cancelada con éxito',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error('Error al cancelar la cita:', error);
        }
    };

    const renderAppointments = () => {
        switch (activeTab) {
            case 'agendadas':
                return appointments.map((appointment, index) => (
                    <div key={index} className="p-4 bg-gray-200 hover:bg-gray-300 shadow-md rounded-lg border border-gray-200 hover:shadow-lg transition duration-200">
                        <div className="flex flex-col justify-between h-full">
                            <div>
                                <h3 className='text-black text-lg font-bold'>Cita con el Doctor</h3>
                                <h3 className="text-lg font-semibold text-blue-600 mb-2">{appointment.doctorName}</h3>
                                <p className="text-sm text-black mb-1">
                                    <strong>Fecha:</strong> {appointment.date.format('DD/MM/YYYY')}
                                </p>
                                <p className="text-sm text-black mb-1">
                                    <strong>Hora:</strong> {appointment.date.format('hh:mm A')}
                                </p>
                                <p className="text-sm text-black mb-3">
                                    <strong>Motivo de la consulta:</strong> {appointment.notes || ''}
                                </p>
                            </div>
                            <div className="mt-auto">
                                <div className="text-sm text-black mb-2">
                                    <strong>Tiempo restante:</strong> {getTimeLeft(appointment.date)}
                                </div>

                                <div className="mt-4 flex gap-4 justify-between">
                                    <button
                                        onClick={() => handleVideoCall(appointment.appointmentId)}
                                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Ir a la VideoConsulta
                                    </button>
                                    <button
                                        onClick={() => handleCancel(appointment.appointmentId)}
                                        className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Cancelar cita
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ));
            case 'finalizadas':
                return allAppointments.filter(appointment => appointment.estado === 'Finalizada').map((appointment, index) => (
                    <div key={index} className="p-4 bg-gray-200 shadow-md rounded-lg border border-gray-200">
                        <div className="flex flex-col justify-between h-full">
                            <div>
                                <h3 className='text-black text-lg font-bold'>Cita con el doctor</h3>
                                <h3 className="text-lg font-semibold text-blue-600 mb-2">{appointment.doctor.fullName}</h3>
                                <p className="text-sm text-black mb-1">
                                    <strong>Fecha:</strong> {moment.utc(appointment.date).format('DD/MM/YYYY')}
                                </p>
                                <p className="text-sm text-black mb-1">
                                    <strong>Hora:</strong> {moment.utc(appointment.date).format('hh:mm A')}
                                </p>
                                <p className="text-sm text-black mb-3">
                                    <strong>Motivo de la consulta:</strong> {appointment.notes || ''}
                                </p>
                            </div>
                        </div>
                    </div>
                ));
            case 'canceladas':
                return allAppointments.filter(appointment => appointment.estado === 'Cancelada').map((appointment, index) => (
                    <div key={index} className="p-4 bg-gray-200 shadow-md rounded-lg border border-gray-200">
                        <div className="flex flex-col justify-between h-full">
                            <div>
                                <h3 className='text-black text-lg font-bold'>Cita con el Doctor</h3>
                                <h3 className="text-lg font-semibold text-blue-600 mb-2">{appointment.doctor.fullName} </h3>
                                <p className="text-sm text-black mb-1">
                                    <strong>Fecha:</strong> {moment.utc(appointment.date).format('DD/MM/YYYY')}
                                </p>
                                <p className="text-sm text-black mb-1">
                                    <strong>Hora:</strong> {moment.utc(appointment.date).format('hh:mm A')}
                                </p>
                                <p className="text-sm text-black mb-1">
                                    <strong>Cancelada por:</strong>
                                    {appointment.canceladoPor === 'patient'
                                        ? ' Paciente'
                                        : appointment.canceladoPor === 'doctor'
                                            ? ' Doctor'
                                            : ''}
                                </p>

                                <p className="text-sm text-black mb-3">
                                    <strong>Motivo de la cancelación:</strong> {appointment.motivoCancelacion || ''}
                                </p>
                            </div>
                        </div>
                    </div>
                ));
            default:
                return null;
        }
    };

    return (
        <div className="max-w-full max-h-full mx-auto p-6 bg-gray-50 shadow-lg rounded-lg">
            <div className="flex justify-between mb-6">
                <h2 className="text-2xl font-bold text-center mb-6 text-gray-700">Mis Citas</h2>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                        <span className="block sm:inline">{error}</span>
                    </div>
                )}

                <button onClick={updateAllAppointments} className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
                    Actualizar Citas
                </button>

            </div>

            <div className="flex justify-center mb-6">
                <button onClick={() => setActiveTab('agendadas')} className={`px-4 py-2 mr-2 ${activeTab === 'agendadas' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'bg-gray-200 text-gray-800'} rounded`}>
                    Agendadas
                </button>
                <button onClick={() => setActiveTab('finalizadas')} className={`px-4 py-2 mr-2 ${activeTab === 'finalizadas' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'bg-gray-200 text-gray-800'} rounded`}>
                    Finalizadas
                </button>
                <button onClick={() => setActiveTab('canceladas')} className={`px-4 py-2 ${activeTab === 'canceladas' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'bg-gray-200 text-gray-800'} rounded`}>
                    Canceladas
                </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {renderAppointments()}
            </div>

            {!appointments.length && activeTab === 'agendadas' && (
                <p className="text-center text-gray-600 mt-6">No hay citas programadas para las próximas 48 horas.</p>
            )}
            {/* Modal de confirmación */}
            {showConfirmationModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <p>¿Estás seguro de que quieres cancelar esta cita?</p>
                        <div className="flex justify-end mt-4">
                            <button onClick={() => setShowConfirmationModal(false)} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">No</button>
                            <button onClick={confirmCancel} className="bg-red-600 text-white px-4 py-2 rounded">Sí</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal para ingresar motivo de cancelación */}
            {showReasonModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                    <p>Por favor, ingresa el motivo de la cancelación:</p>
                    <textarea 
                            placeholder="Motivo de la cancelación" 
                            value={cancelReason} 
                            onChange={(e) => setCancelReason(e.target.value)} 
                            className="mt-2 border p-2 rounded w-full"
                        />
                        <div className="flex justify-end mt-4">
                            <button onClick={() => setShowReasonModal(false)} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">Cancelar</button>
                            <button onClick={confirmCancelWithReason} className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded">Confirmar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpcomingPatientAppointments;
