import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Asegúrate de tener esto importado
import { useNavigate } from 'react-router-dom';

const ConsentimientoInformado = ({ appointmentId, onConsentAccepted }) => {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [appointmentInfo, setAppointmentInfo] = useState(null);

  const [isDrawing, setIsDrawing] = useState(false);

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const clearSignature = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Limpiar el canvas
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };
  const fetchAppointmentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setAppointmentInfo(response.data);
    } catch (error) {
      console.error('Error al obtener la información de la cita:', error);
    }
  };

  useEffect(() => {
    fetchAppointmentInfo();
  }, [appointmentId]);

  const handleAccept = async () => {
    // Verificar si el canvas está vacío
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const canvasData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const isEmpty = canvasData.data.every((value) => value === 0); // Verificar si todos los valores son cero (transparente)

    if (isEmpty) {
        // Si el canvas está vacío, mostrar un mensaje de advertencia
        Swal.fire({
            title: 'Advertencia',
            text: 'Por favor, firme para continuar.',
            icon: 'warning',
            confirmButtonText: 'Entendido',
        });
        return; // Salir de la función si el canvas está vacío
    }

    try {
        const token = localStorage.getItem('token');
        const clinicId = localStorage.getItem('clinicId');
        const signatureData = canvas.toDataURL('image/png'); // Convertir canvas a imagen PNG

        // Construir el objeto de datos a enviar
        const patientInfo = {
            patientId: appointmentInfo.patientId,
            firstName: appointmentInfo.firstName,
            secondName: appointmentInfo.secondName,
            firstLastName: appointmentInfo.firstLastName,
            secondLastName: appointmentInfo.secondLastName,
            dob: appointmentInfo.dob,
            birthCountry: appointmentInfo.birthCountry,
            residenceCountry: appointmentInfo.residenceCountry,
            department: appointmentInfo.department,
            city: appointmentInfo.city,
            street: appointmentInfo.street,
            exteriorNumber: appointmentInfo.exteriorNumber,
            interiorNumber: appointmentInfo.interiorNumber,
            neighborhood: appointmentInfo.neighborhood,
            gender: appointmentInfo.gender,
            cellNumber: appointmentInfo.cellNumber,
            email: appointmentInfo.email,
            idType: appointmentInfo.idType,
            documentNumber: appointmentInfo.documentNumber,
            hasMedicalInsurance: appointmentInfo.hasMedicalInsurance,
            insuranceName: appointmentInfo.insuranceName,
            insuranceNumber: appointmentInfo.insuranceNumber,
            hasCarer: appointmentInfo.hasCarer,
            carerFirstName: appointmentInfo.carerFirstName,
            carerSecondName: appointmentInfo.carerSecondName,
            carerFirstLastName: appointmentInfo.carerFirstLastName,
            carerSecondLastName: appointmentInfo.carerSecondLastName,
            carerRelationship: appointmentInfo.carerRelationship,
            carerIdType: appointmentInfo.carerIdType,
            carerIdNumber: appointmentInfo.carerIdNumber,
        };

        const response = await axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/api/pdf/consentimientoInformado`,
            method: 'POST',
            data: {
                appointmentId,
                clinicId,
                patientInfo,
                doctorId: appointmentInfo.doctorId,
                signature: signatureData,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        });

        if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `consentimiento-${appointmentId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            onConsentAccepted();
            
            Swal.fire({
                title: '¡Consentimiento Aceptado con éxito!',
                text: 'El PDF se ha descargado correctamente.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });
        } else {
            console.error('La respuesta del servidor no es un PDF válido');
            Swal.fire({
                title: 'Error',
                text: 'La respuesta del servidor no contiene un archivo PDF válido.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    } catch (error) {
        console.error('Error al enviar el consentimiento:', error);
        Swal.fire({
            title: 'Error',
            text: 'Hubo un problema al enviar el consentimiento.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
        });
    }
};



  
  
  
  const handleDecline = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Consentimiento Rechazado',
      text: 'Recuerde que si no acepta el consentimiento, no puede ingresar a la videoconsulta.',
      confirmButtonText: 'Entendido',
    });
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen overflow-y-auto bg-gray-100 p-2">
<h2 className="text-2xl font-bold mb-4 text-center">SERVICIOS DE LA RED COLOMBIANA DE PRESTACIÓN Y CUIDADOS EN SALUD</h2>
      <h3 className="text-xl font-semibold mb-2 text-center">CONSENTIMIENTO INFORMADO</h3>

      <div className="text-left mb-6 max-w-3xl">
        
        
        <p><strong>Descripción de la modalidad de consulta:</strong></p>
        <p>
        Consiste en la provisión de servicios de salud a distancia en los componentes de promoción, prevención, diagnóstico, tratamiento y rehabilitación, por profesionales de la salud que utilizan tecnologías de la información y la comunicación, que les permiten intercambiar datos con el propósito de facilitar el acceso y la oportunidad en la prestación de servicios a la población que presenta limitaciones de oferta, de acceso a los servicios o de ambos en su área geográfica. Este procedimiento permite la realización de interconsultas y el acceso de manera más ágil a la evaluación de la historia clínica por médicos especialistas en los términos de la Ley 1419 de 2010.
        </p>
<br/>
        <p><strong>Declaración del usuario:</strong></p>
        <p>
        Actuando a nombre propio comprendo que, en el curso de mi consulta médica, la información sobre mi persona, la cual en adelante se denominará “información personal”, es útil para mi atención médica, autorizo que la misma pueda ser recolectada, almacenada y transmitida a personal médico de LA RED COLOMBIANA DE PRESTACIÓN Y CUIDADOS EN SALUD. Esta información puede incluir datos con los que se me puede identificar, como mi nombre, fecha de nacimiento e historia clínica. Asimismo, autorizo que en caso de que los médicos consideren pertinente me tomen fotografías, graben videos o audio y utilicen cualquier formato multimedia que sea necesario dentro de la modalidad de servicio de TELEMEDICINA, los cuales harán parte integrante de la historia clínica.
Autorizo  SI / NO  la utilización de cualquier equipo biomédico electrónico durante la prestación del servicio de salud en la modalidad de TELEMEDICINA.
Autorizo SI / NO  que mi información personal pueda ser compartida y utilizada por LA RED COLOMBIANA DE PRESTACIÓN Y CUIDADOS EN SALUD y otros profesionales de la salud para manejar mi caso y asistir en la evaluación, el diagnóstico y el tratamiento de mi condición. Estos profesionales de la salud pueden habitar fuera de mi municipio de residencia y estar ubicados por fuera de la cobertura. Esta información puede ser transmitida en tiempo real utilizando equipos de videoconferencia donde el (los) médico(s) en el CENTRO DE REFERENCIA observarán, participarán y emitirán comentarios o sugerencias a distancia.

        </p>
<br/> 
        <p><strong>Aceptación de la prestación del servicio:</strong></p>
        <p>
        De manera voluntaria y con pleno consentimiento autorizo SI / NO  que mi proceso de atención sea efectuado bajo la modalidad de servicio de Telemedicina, de igual manera estoy de acuerdo con las consultas médicas con especialistas e interconsultas por este medio.
Al firmar debajo confirmo que he leído, comprendido por completo y estoy de acuerdo con todo lo anterior, o si no soy capaz de leer los párrafos previos, yo confirmo que este consentimiento me ha sido completamente leído y que estoy de acuerdo con todo su contenido.

        </p>
<br/>
        <p><strong>Consentimiento informado diferido:</strong></p>
        <p>
        Este numeral debe ser diligenciado por el representante legal del usuario cuando éste no tiene la capacidad legal para otorgar su consentimiento (menor de edad, inconsciencia, alteración mental temporal o definitiva).
        Dado que el beneficiario de los procedimientos es considerado incapaz o incompetente para tomar o rechazar la decisión o dar su consentimiento, el profesional me ha explicado, en mi calidad de representante legal, en forma clara, expresa y satisfactoria en qué consiste la telemedicina y sus implicaciones. 
        </p>
      </div>

      {/* Canvas para la firma digital */}
      <div className="mb-6">
        <p className="text-lg mb-2">Firma del Usuario:</p>
        <canvas
          ref={canvasRef}
          className="border border-gray-500 rounded-md"
          width={400}
          height={150}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing} // Detener dibujo si el cursor sale del canvas
        />
      </div>

      {/* Botones de Aceptar y Rechazar */}
      <div className="flex space-x-4">
        <button
          onClick={handleAccept}
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Aceptar
        </button>
        <button
          onClick={clearSignature}
          className="bg-blue-900 text-white px-4 py-2 rounded-md"
        >
          Limpiar Firma
        </button>
        <button
          onClick={handleDecline}
          className="bg-red-500 text-white px-4 py-2 rounded-md"
        >
          Rechazar
        </button>
      </div>
    </div>
  );
};

export default ConsentimientoInformado;
